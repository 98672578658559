import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Board = ({ handleDrop, children }) => {
  return (
    <DragDropContext onDragEnd={handleDrop}>
      <div
        style={{
          width: "100%",
          minWidth: "1100px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          flexGrow: "1",
          marginBottom: "-30px",
        }}
      >
        {children}
      </div>
    </DragDropContext>
  );
};

export default Board;
