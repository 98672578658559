import { Droppable } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Box } from "@material-ui/core";
import Card from "components/Card";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: "1",
    borderRadius: "5px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    border: "1px dashed transparent",
  },
  columnContainer: {
    height: "calc(100% - 75px)",
    width: "100%",
    marginRight: "22px",
    "&:last-child": {
      marginRight: "0px",
    },
  },
}));

const PointsPill = ({ points }) => {
  return (
    <Typography
      variant="body2"
      style={{
        height: "22px",
        width: "20px",
        fontWeight: "500",
        padding: "4px 6px",
        borderRadius: "5px",
        color: "#40BB97",
        backgroundColor: "#ececec",
        boxSizing: "border-box",
        lineHeight: "15px",
      }}
    >
      {points ? points : "-"}
    </Typography>
  );
};

const StatusColumn = ({ statusText, statusColor, tasks, handleCardClick }) => {
  const classes = useStyles();
  const totalPoints = tasks.reduce(
    (total, { storyPoints = 0 }) => total + storyPoints,
    0
  );

  return (
    <div id="column-container" className={classes.columnContainer}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom="14px"
        borderBottom={`4px solid ${statusColor}`}
        marginBottom="18px"
      >
        <Typography
          variant="body2"
          style={{
            color: "#7E7E7E",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {statusText}
        </Typography>
        <PointsPill points={totalPoints} />
      </Box>

      <Droppable droppableId={statusText}>
        {(provided, snapshot) => {
          return (
            <Paper
              elevation={0}
              classes={{ root: classes.root }}
              style={
                snapshot.isDraggingOver
                  ? {
                      boxShadow: "1px 1px 4px #e5e9e6",
                      border: `1px dashed ${statusColor}`,
                    }
                  : {}
              }
            >
              <div
                id="task-container"
                style={{ height: "100%" }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {tasks.length > 0 &&
                  tasks.map((task, index) => (
                    <Card
                      key={task.id}
                      index={index}
                      task={task}
                      onCardClick={handleCardClick}
                    />
                  ))}
              </div>
              {provided.placeholder}
            </Paper>
          );
        }}
      </Droppable>
    </div>
  );
};

export default StatusColumn;
