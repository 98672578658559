import { useSelector } from "react-redux";
import { useAuthStateChanged } from "hooks";
import { Container, SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Layout } from "layouts";
// import { ReactComponent as LogoMark } from "assets/logo-mark-color.svg";
import { ReactComponent as LogoMark } from "assets/scrumist-logo-mark.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    height: " 100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const ProtectedPage = ({ children, isLoading }) => {
  const { isLoggedIn } = useAuthStateChanged();

  const classes = useStyles();

  const isAppLoading = useSelector((state) => state.app.isLoading);
  const [isProtectedPageLoading, setIsProtectedPageLoading] = useState(true);
  const [startLoadingTime, setStartLoadingTime] = useState(moment().valueOf());
  const [doneLoadingTime, setDoneLoadingTime] = useState(null);

  useEffect(() => {
    if (isLoggedIn && !isAppLoading && !isLoading) {
      setDoneLoadingTime(moment().valueOf());
    } else {
      setIsProtectedPageLoading(true);
    }
  }, [isAppLoading, isLoading, isLoggedIn]);

  useEffect(() => {
    let clearTimeout = null;

    if (doneLoadingTime) {
      const timeLoading = doneLoadingTime - startLoadingTime;

      if (timeLoading >= 1500) {
        setIsProtectedPageLoading(false);
      } else {
        clearTimeout = setTimeout(() => {
          setIsProtectedPageLoading(false);
        }, 1500 - timeLoading);

        return clearTimeout;
      }
    }
  }, [startLoadingTime, doneLoadingTime]);

  if (isLoggedIn && !isProtectedPageLoading) {
    return children;
  }

  return (
    <Layout pageTitle="Scrumist" showNav={false}>
      <Container className={classes.root}>
        <SvgIcon
          viewBox="0 0 50 76"
          style={{
            display: "block",
            margin: "0 auto",
            marginBottom: "33px",
            width: "75px",
            height: "75px",
            position: "absolute",
            left: "calc(50% - 37.5px)",
            top: "calc(45% - 37.5px)",
          }}
        >
          <LogoMark />
        </SvgIcon>
        <CircularProgress
          style={{
            position: "absolute",
            left: "calc(50% - 75px)",
            top: "calc(45% - 75px)",
          }}
          size={150}
          color="primary"
        />
      </Container>
    </Layout>
  );
};

export default ProtectedPage;
