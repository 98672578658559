import { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  Container,
  SvgIcon,
  Card,
  CardHeader,
  CardActions,
  Grid,
  Box,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@material-ui/core";
import { Layout, ProtectedPage } from "layouts";
import { useAuthStateChanged, useCheckOnboardingState } from "hooks";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { ReactComponent as MainLogo } from "assets/scrumist-logo-full.svg";
import { useStripe } from "@stripe/react-stripe-js";
import { ReactComponent as LogoMark } from "assets/scrumist-logo-mark.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { app, auth } from "utilities/firebase";
import {
  getFirestore,
  doc,
  addDoc,
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { findApiError } from "utilities";
import NavBar from "layouts/Navbar";

const db = getFirestore(app);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  paper: {
    height: "calc(100% - 106px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "80px",
    paddingTop: "0px",
    alignItems: "center",
  },
  grid: {
    marginTop: "10px",
    marginBottom: "35px",
  },
  card: {
    height: "100%",
    marginBottom: "15px",
  },
  cardHeader: ({ highlightColor }) => {
    return {
      backgroundColor: highlightColor,
    };
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.background,
    padding: "6px 12px",
    borderRadius: "5px",
  },
  cardActions: {
    justifyContent: "center",
    marginBottom: "25px",
  },
}));

const SubscriptionCard = ({
  type,
  selectSubscription,
  description,
  highlightColor,
  features = [],
  monthlyPrice,
  isAvailable,
}) => {
  useCheckOnboardingState();

  const cardStyles = useStyles().card;
  const cardHeaderStyles = useStyles({ highlightColor }).cardHeader;
  const cardActionStyles = useStyles().cardActions;

  return (
    <Box style={{ height: "100%" }}>
      <Card className={cardStyles} raised>
        <CardHeader disableTypography className={cardHeaderStyles} />
        <CardContent>
          <Typography variant="h3" gutterBottom>
            {type}
          </Typography>
          <Typography variant="subtitle1">{description}</Typography>
          <Box m="25px 0px">
            <Grid
              container
              align="center"
              justifyContent="center"
              direction="column"
              spacing={1}
            >
              <Grid item>
                <Typography variant="h4">
                  ${monthlyPrice}
                  <Typography variant="caption"> USD</Typography>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  Per month, billed annually at ${monthlyPrice * 12}/year
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
          <List dense={false}>
            {features.map((f) => {
              return (
                <ListItem key={f}>
                  <ListItemIcon>
                    <CheckRoundedIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={f} />
                </ListItem>
              );
            })}
          </List>
        </CardContent>
        <CardActions className={cardActionStyles}>
          <Button
            variant="outlined"
            color="primary"
            onClick={selectSubscription}
            disabled={!isAvailable}
            size="medium"
            disableRipple
          >
            {isAvailable ? "Select" : "Coming Soon"}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

const createCheckoutSesssion = async ({
  stripe,
  priceId,
  promotionCode = null,
}) => {
  const userRef = doc(db, "users", auth.currentUser.uid);
  const checkoutSessionsRef = collection(userRef, "checkout_sessions");

  return new Promise(async (resolve, reject) => {
    const checkoutSessionRef = await addDoc(checkoutSessionsRef, {
      price: priceId,
      allow_promotion_codes: true,
      trial_from_plan: true,
      success_url: `${window.location.origin}/`,
      cancel_url: `${window.location.origin}/`,
      promotion_code: promotionCode,
    });

    await onSnapshot(checkoutSessionRef, async (snapshot) => {
      const { error, sessionId } = snapshot.data();

      if (error) {
        reject(error);
      }

      if (sessionId) {
        await stripe.redirectToCheckout({ sessionId });
      }
    });
  });
};

const SelectSubscription = () => {
  const stripe = useStripe();
  const classes = useStyles();

  const isAppReady = useSelector((state) => state.app.isInitialized);
  const promotionCode = useSelector((state) => state.user.promotionCode);

  const [products, setProducts] = useState([]);
  const [productsChecked, setProductsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const [checkoutError, setCheckoutError] = useState("");

  const handleSelectSubscription = (selectedProduct) => async () => {
    setCheckoutError("");
    setIsCheckoutLoading(true);

    try {
      await createCheckoutSesssion({
        stripe,
        priceId: selectedProduct.priceId,
        promotionCode,
      });
    } catch (error) {
      const foundError = findApiError(error);

      // Retry if its related to a messaed up promotion code
      if (
        error.message?.includes("No such promotion code") ||
        error.message.includes("This promotion code has expired")
      ) {
        try {
          await createCheckoutSesssion({
            stripe,
            priceId: selectedProduct.priceId,
          });
        } catch (error) {
          const foundError = findApiError(error);
          setCheckoutError(foundError);
          setIsCheckoutLoading(false);
        }
      } else {
        setCheckoutError(foundError);
        setIsCheckoutLoading(false);
      }
    }
  };

  const getProductList = useCallback(async () => {
    try {
      const productsSnapshot = await getDocs(
        query(collection(db, "products"), where("active", "==", true))
      );

      let products = [];

      for (const productDoc of productsSnapshot.docs) {
        const product = {
          id: productDoc.id,
          prices: [],
          ...productDoc.data(),
        };

        const priceSnap = await getDocs(collection(productDoc.ref, "prices"));

        priceSnap.docs.forEach((priceDoc) => {
          product.prices.push({
            id: priceDoc.id,
            ...priceDoc.data(),
          });
        });

        products.push({
          name: product.name,
          description: product.description,
          productColor: product.stripe_metadata_productColor,
          features: JSON.parse(product.stripe_metadata_features),
          isAvailable:
            product.stripe_metadata_isAvailable === "true" ? true : false,
          monthlyPrice: product.prices.length
            ? product.prices[0].unit_amount / 100 / 12
            : 0,
          priceId: product.prices.length ? product.prices[0].id : null,
        });
      }

      setProducts(products);
    } catch (error) {
      console.log({ error });
    }
  }, []);

  useEffect(() => {
    if (isAppReady && !products.length && !productsChecked) {
      getProductList();
      setProductsChecked(true);
    }

    if (isLoading && products.length) {
      setIsLoading(false);
    }
  }, [isAppReady, products.length, productsChecked, getProductList, isLoading]);

  return (
    <ProtectedPage isLoading={isLoading || isCheckoutLoading}>
      <Layout pageTitle="Select Subscription" showNav={false}>
        <NavBar transparent={true} showProjectName={false} />
        <Paper elevation={0} classes={{ root: classes.paper }}>
          <Container className={classes.root}>
            <SvgIcon
              viewBox="0 0 455 82"
              style={{
                display: "block",
                width: "400px",
                height: "175px",
                margin: "0 auto",
              }}
            >
              <MainLogo />
            </SvgIcon>

            <Typography variant="h1" align="center" gutterBottom>
              Pick your subscription
            </Typography>
            {checkoutError && (
              <Typography
                variant="subtitle2"
                align="center"
                classes={{ root: classes.error }}
                gutterBottom
              >
                {checkoutError}
              </Typography>
            )}

            <Grid
              container
              spacing={6}
              justifyContent="center"
              className={classes.grid}
            >
              {products.map((p) => {
                return (
                  <Grid
                    item
                    sm={12}
                    md={4}
                    key={p.name}
                    style={{ width: "100%" }}
                  >
                    <SubscriptionCard
                      type={p.name}
                      description={p.description}
                      highlightColor={p.productColor}
                      features={p.features}
                      monthlyPrice={p.monthlyPrice}
                      isAvailable={p.isAvailable}
                      selectSubscription={handleSelectSubscription(p)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </Paper>
      </Layout>
    </ProtectedPage>
  );
};

export default SelectSubscription;
