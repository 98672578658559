import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { auth } from "utilities/firebase";
import { userIsLoggedIn, userNotLoggedIn } from "store/user/actions";
import { onAuthStateChanged } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";

const useAuthStateChanged = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAtLogin = location.pathname === "/login";
  const isAtSignUp = location.pathname === "/signup";
  const isUserLoggedIn = useSelector((state) => state.user.loggedIn);
  const isAppInitialized = useSelector((state) => state.app.isInitialized);
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (!isUserLoggedIn) {
          setTimeout(() => {
            dispatch(userIsLoggedIn({ user }));
          }, 1500);
        }
      } else if (!user) {
        dispatch(userNotLoggedIn());
      }
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    // If the app has finished initial load,
    // and the user is logged in,
    // and the user is on the login page
    // then redirect them to "/app"
    if (isAppInitialized && isUserLoggedIn) {
      if (isAtLogin || isAtSignUp) {
        navigate("/", { replace: true });
      }
      // Otherwise remain on protected page

      // If the app has finished initial load,
      // and the user is not logged in,
      // and currently routed to a a protected page in app
      // route back to login
    } else if (isAppInitialized && !isUserLoggedIn) {
      if (!isAtLogin && !isAtSignUp) {
        navigate("/login", { replace: true });
      }
    }
  }, [isAppInitialized, isUserLoggedIn, isAtLogin, isAtSignUp, navigate]);

  const isLoggedIn = isAppInitialized && isUserLoggedIn;
  const isCheckingAuth = !isAppInitialized || isLoggedIn;

  return {
    isLoggedIn,
    isCheckingAuth,
  };
};

export default useAuthStateChanged;
