import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import Sidebar from "./Sidebar";
import Layout from "./Layout";
import LayersIcon from "@material-ui/icons/Layers";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import ProjectsIcon from "@material-ui/icons/TableChart";
import SettingsIcon from "@material-ui/icons/Settings";
import { useLocation } from "react-router-dom";
import NavBar from "./Navbar";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "calc(100% - 106px)",
    padding: "50px",
    overflowY: "overlay",
  },
}));

const LayoutWithSidebar = ({
  PageActionComponent,
  dynamicPageName,
  pageStyles,
  showProjectName,
  children,
}) => {
  const location = useLocation();
  const classes = useStyles();

  const routes = [
    {
      Icon: DateRangeIcon,
      pathname: "/",
      name: "Active Sprint",
    },
    {
      Icon: ClearAllIcon,
      pathname: "/backlog",
      name: "Backlog",
    },
    {
      Icon: ProjectsIcon,
      pathname: "/projects",
      name: "Projects",
    },
    {
      Icon: SettingsIcon,
      pathname: "/settings",
      name: "Settings",
    },
  ];

  const getPage = () => {
    const route = routes.filter((r) => r.pathname === location.pathname);
    if (route.length > 0) {
      return { title: `${route[0].name} | Scrumist`, name: route[0].name };
    }

    return {
      title: "Scrumist",
      name: "",
    };
  };

  const page = getPage();

  return (
    <Layout pageTitle={page.title} rootPageStyles={pageStyles}>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        <Sidebar routes={routes} />
        <div style={{ width: "calc(100% - 260px)", height: "100%" }}>
          <NavBar
            pageName={dynamicPageName || page.name}
            PageActionComponent={PageActionComponent}
            showProjectName={showProjectName}
          />
          <Paper
            component="main"
            elevation={0}
            classes={{ root: classes.main }}
          >
            {children}
          </Paper>
        </div>
      </div>
    </Layout>
  );
};

export default LayoutWithSidebar;
