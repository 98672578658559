import { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Container,
  SvgIcon,
  TextField,
  Box,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Layout } from "layouts";
import { auth } from "utilities/firebase";
import { ReactComponent as MainLogo } from "assets/scrumist-logo-full.svg";
import { useSearchParams, useNavigate } from "react-router-dom";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { findApiError } from "utilities";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "450px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  paper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "80px",
    alignItems: "center",
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.background,
    padding: "6px 12px",
    borderRadius: "5px",
  },
  noBorder: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
}));

const PasswordReset = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState({
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const isFormSubmissionDisabled =
    !formState.password ||
    !formState.confirmPassword ||
    formState.password !== formState.confirmPassword ||
    isLoading;

  const handleFormSubmit = async () => {
    setError("");
    setIsLoading(true);

    const { password, confirmationCode } = formState;

    try {
      await verifyPasswordResetCode(auth, confirmationCode);
      await confirmPasswordReset(auth, confirmationCode, password);
      navigate("/login", { replace: true });
    } catch (error) {
      setError(findApiError(error));
      setIsLoading(false);
    }
  };

  const code = searchParams.get("oobCode");

  useEffect(() => {
    if (code && !formState.confirmationCode) {
      setFormState((prevState) => ({ ...prevState, confirmationCode: code }));
    }
  }, [code, formState.confirmationCode]);

  return (
    <Layout pageTitle={"Reset Password"} showNav={false}>
      <Paper elevation={0} classes={{ root: classes.paper }}>
        <Container className={classes.root}>
          <SvgIcon
            viewBox="0 0 455 82"
            style={{
              display: "block",
              width: "400px",
              height: "175px",
              margin: "0 auto",
            }}
          >
            <MainLogo />
          </SvgIcon>
          <Typography variant="h1" align="center" gutterBottom>
            Reset password
          </Typography>
          <Typography variant="subtitle1" align="center" gutterBottom>
            Enter your new password and confirm
          </Typography>

          {error && (
            <Typography
              variant="subtitle2"
              align="center"
              classes={{ root: classes.error }}
              gutterBottom
            >
              {error}
            </Typography>
          )}
          <fieldset
            style={{
              opacity: isLoading ? ".3" : 1,
              border: "none",
            }}
          >
            <TextField
              id="password"
              variant="outlined"
              margin="normal"
              label="Password"
              name="password"
              type="password"
              value={formState.password}
              onChange={handleInputChange}
              required
              fullWidth
              InputProps={{
                classes: {
                  root: classes.noBorder,
                },
              }}
            />

            <TextField
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              variant="outlined"
              margin="normal"
              label="Confirm Password"
              onChange={handleInputChange}
              value={formState.confirmPassword}
              required
              fullWidth
              InputProps={{
                classes: {
                  root: classes.noBorder,
                },
              }}
            />
          </fieldset>

          <Box width="100%" mt={2}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={isFormSubmissionDisabled}
              onClick={handleFormSubmit}
              style={{ display: "block", width: "100%" }}
              disableRipple
            >
              Reset Password
            </Button>
          </Box>
        </Container>
      </Paper>
    </Layout>
  );
};

export default PasswordReset;
