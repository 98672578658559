import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
  Box,
} from "@material-ui/core";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import CloseIcon from "@material-ui/icons/Close";
import { createSprint, updateSprint } from "store/app/actions";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "30px",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "0px",
    marginBottom: "15px",
  },
  titleIcon: {
    color: " #00C99A",
    height: "26px",
    width: "26px",
    marginRight: "8px",
  },
  closeIcon: {
    height: "28px",
    width: "28px",
    cursor: "pointer",

    "&:hover": {
      color: "#616362",
    },
  },
  titleText: {},
  actions: {},
  noBorder: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
  dialog: {
    overflowY: "initial",
    padding: "0px",
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.background,
    padding: "6px 12px",
    borderRadius: "5px",
  },
}));

const SprintForm = ({
  draftSprint,
  handleTextFieldChange,
  handleDateChange,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TextField
          id="title"
          name="title"
          margin="normal"
          variant="outlined"
          label="Sprint Title"
          required
          fullWidth
          multiline
          maxRows={2}
          value={draftSprint?.title || ""}
          onChange={handleTextFieldChange}
          helperText={`${draftSprint?.title.length} / 128 characters used`}
          InputProps={{
            classes: {
              root: classes.noBorder,
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <KeyboardDatePicker
          autoOk
          id="startDate"
          name="startDate"
          label="Start Date"
          format="MM/DD/yyyy"
          variant="inline"
          inputVariant="outlined"
          InputProps={{
            classes: {
              root: classes.noBorder,
            },
          }}
          fullWidth
          InputAdornmentProps={{ position: "end" }}
          KeyboardButtonProps={{
            color: "primary",
            size: "small",
          }}
          value={draftSprint?.startDate || null}
          onChange={(date) => handleDateChange("startDate", date)}
        />
      </Grid>
      <Grid item xs={6}>
        <KeyboardDatePicker
          autoOk
          id="endDate"
          name="endDate"
          label="End Date"
          format="MM/DD/yyyy"
          variant="inline"
          inputVariant="outlined"
          InputProps={{
            classes: {
              root: classes.noBorder,
            },
          }}
          InputAdornmentProps={{ position: "end" }}
          KeyboardButtonProps={{
            color: "primary",
            size: "small",
          }}
          value={draftSprint?.endDate || null}
          onChange={(date) => handleDateChange("endDate", date)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="description"
          name="description"
          label="Description"
          multiline
          minRows={6}
          maxRows={20}
          variant="outlined"
          fullWidth
          margin="normal"
          value={draftSprint?.description || ""}
          onChange={handleTextFieldChange}
          InputProps={{
            classes: {
              root: classes.noBorder,
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

const defaultSprintState = {
  title: "",
  startDate: "",
  endDate: "",
  description: "",
};

const SprintModal = ({ isOpen, handleClose, activeSprintId, sprint }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const modalType = activeSprintId ? "Edit" : "Create";
  const modalTitle = modalType === "Create" ? "Create Sprint" : "Edit Sprint";
  const modalActionButtonText = modalType === "Create" ? "Create" : "Update";
  const error = useSelector((state) => state.app.error);
  const isLoading = useSelector((state) => state.app.isFormLoading);

  const [draftSprint, updateDraftSprint] = useState({
    ...defaultSprintState,
    ...sprint,
  });

  useEffect(() => {
    updateDraftSprint(sprint);
  }, [sprint]);

  const handleTextFieldChange = (e) => {
    updateDraftSprint({
      ...draftSprint,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (name, date) => {
    updateDraftSprint({
      ...draftSprint,
      [name]: date,
    });
  };

  const handleUpdateSprint = () => {
    const { title, startDate, endDate, description } = draftSprint;

    dispatch(
      updateSprint({
        title,
        startDate: startDate ? moment(startDate).format("MM/DD/YYYY") : null,
        endDate: endDate ? moment(endDate).format("MM/DD/YYYY") : null,
        description,
        closeModal: handleClose,
      })
    );
  };

  const handleCreateSprint = () => {
    const { title, startDate, endDate, description } = draftSprint;

    dispatch(
      createSprint({
        title,
        startDate: startDate ? moment(startDate).format("MM/DD/YYYY") : null,
        endDate: endDate ? moment(endDate).format("MM/DD/YYYY") : null,
        description,
        closeModal: handleClose,
      })
    );
  };

  const handleSubmitAction =
    modalType === "Create" ? handleCreateSprint : handleUpdateSprint;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
      classes={{ paper: classes.paper }}
      aria-labelledby="Sprint-Modal"
    >
      <DialogTitle id={modalTitle} className={classes.title} disableTypography>
        <Box display="flex" alignItems="center" width="100%">
          <DirectionsRunIcon className={classes.titleIcon} />
          <Typography variant="h4" className={classes.titleText}>
            {modalTitle}
          </Typography>
        </Box>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </DialogTitle>
      {error && (
        <Typography
          variant="subtitle2"
          align="left"
          classes={{ root: classes.error }}
          gutterBottom
        >
          {error}
        </Typography>
      )}
      <DialogContent className={classes.dialog}>
        <fieldset
          style={{
            opacity: isLoading ? ".3" : 1,
            border: "none",
          }}
        >
          <SprintForm
            draftSprint={draftSprint}
            handleTextFieldChange={handleTextFieldChange}
            handleDateChange={handleDateChange}
          />
        </fieldset>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          disableRipple
          onClick={handleClose}
          style={{
            boxSizing: "border-box",
            width: "75px",
          }}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableRipple
          size="small"
          onClick={handleSubmitAction}
          style={{
            boxSizing: "border-box",
            width: "75px",
          }}
          disabled={isLoading}
        >
          {modalActionButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SprintModal;
