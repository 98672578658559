import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  FormControl,
  FilledInput,
  InputLabel,
  Container,
  SvgIcon,
  Paper,
  Link as MaterialLink,
  TextField,
  Box,
} from "@material-ui/core";
import { Layout } from "layouts";
import { createUserWithEmailAndPassword } from "store/user/actions";
import { useAuthStateChanged } from "hooks";
import { ReactComponent as MainLogo } from "assets/scrumist-logo-full.svg";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "450px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  paper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "80px",
    alignItems: "center",
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.background,
    padding: "6px 12px",
    borderRadius: "5px",
  },
  noBorder: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
}));

const Signup = () => {
  useAuthStateChanged();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const promotionCode = searchParams.get("promoCode");
  useEffect(() => {
    if (promotionCode) {
      // Save it for later
      setFormState((prevState) => ({ ...prevState, promotionCode }));

      // Clear it from url
      setSearchParams({});
    }
  }, [promotionCode, setSearchParams]);

  const apiError = useSelector((state) => state.user.formError);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [formState, setFormState] = useState({
    displayName: "",
    email: "",
    password: "",
    confirmPassword: "",
    mandatory: "",
    promotionCode: null,
  });

  useEffect(() => {
    setError(apiError);

    if (apiError) {
      setIsLoading(false);
    }
  }, [apiError]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFormSubmit = async () => {
    setError("");
    setIsLoading(true);
    const {
      displayName,
      email,
      password,
      confirmPassword,
      mandatory,
      promotionCode,
    } = formState;
    const passwordsMatch = password === confirmPassword;

    if (passwordsMatch) {
      dispatch(
        createUserWithEmailAndPassword({
          displayName,
          email,
          password,
          mandatory,
          promotionCode,
        })
      );
    } else {
      setError("Passwords don't match");
      setIsLoading(false);
    }
  };

  return (
    <Layout pageTitle={"Create Account"} showNav={false}>
      <Paper elevation={0} classes={{ root: classes.paper }}>
        <Container className={classes.root}>
          <SvgIcon
            viewBox="0 0 455 82"
            style={{
              display: "block",
              width: "400px",
              height: "175px",
              margin: "0 auto",
            }}
          >
            <MainLogo />
          </SvgIcon>

          <Typography variant="h1" align="center" gutterBottom>
            Create your account
          </Typography>

          {error && (
            <Typography
              variant="subtitle2"
              align="center"
              classes={{ root: classes.error }}
              gutterBottom
            >
              {error}
            </Typography>
          )}

          <fieldset
            style={{
              opacity: isLoading ? ".3" : 1,
              border: "none",
            }}
          >
            <TextField
              type="text"
              id="displayName"
              name="displayName"
              label="Display Name"
              fullWidth
              placeholder="John Doe"
              onChange={handleInputChange}
              value={formState.displayName}
              required
              margin="normal"
              variant="outlined"
              InputProps={{
                classes: {
                  root: classes.noBorder,
                },
              }}
            />

            <TextField
              id="email"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              placeholder="john.doe@scrumist.io"
              onChange={handleInputChange}
              value={formState.email}
              required
              margin="normal"
              variant="outlined"
              InputProps={{
                classes: {
                  root: classes.noBorder,
                },
              }}
            />

            <TextField
              id="password"
              variant="outlined"
              name="password"
              type="password"
              required
              label="Password"
              value={formState.password}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              InputProps={{
                classes: {
                  root: classes.noBorder,
                },
              }}
            />

            <TextField
              id="confirmPassword"
              variant="outlined"
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              onChange={handleInputChange}
              value={formState.confirmPassword}
              required
              fullWidth
              margin="normal"
              InputProps={{
                classes: {
                  root: classes.noBorder,
                },
              }}
            />

            <FormControl
              variant="filled"
              style={{
                visibility: "hidden",
                height: "1px",
                width: "1px",
                margin: "0px",
                display: "block",
              }}
            >
              <InputLabel htmlFor="mandatory">Mandatory</InputLabel>
              <FilledInput
                id="mandatory"
                name="mandatory"
                type="text"
                onChange={handleInputChange}
                value={formState.mandatory}
                fullWidth
              />
            </FormControl>
          </fieldset>

          <Box width="100%" mt={2} mb={2}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleFormSubmit}
              style={{ display: "block", width: "100%" }}
              disableRipple
              disabled={isLoading}
            >
              Create Account
            </Button>
          </Box>

          <Typography variant="body2" align="center" gutterBottom>
            By continuing with Email, you agree to Scrumists's
            <br />
            <MaterialLink
              href="https://scrumist.io/terms"
              target="_blank"
              rel="noreferrer"
              style={{ margin: "0px 4px" }}
            >
              Terms of Service
            </MaterialLink>
            and
            <MaterialLink
              href="https://scrumist.io/privacy"
              target="_blank"
              rel="noreferrer"
              style={{ marginLeft: "4px" }}
            >
              Privacy Policy
            </MaterialLink>
            .
          </Typography>
        </Container>
      </Paper>
    </Layout>
  );
};

export default Signup;
