import { Typography, Paper } from "@material-ui/core";

const NoDataPaperCard = ({ message, highlight }) => {
  return (
    <Paper
      elevation={0}
      style={{
        height: "90px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: highlight ? "transparent" : "#cbece2",
      }}
    >
      <Typography variant="body2" align="center">
        {highlight ? "" : message}
      </Typography>
    </Paper>
  );
};

export default NoDataPaperCard;
