import { Droppable, Draggable } from "react-beautiful-dnd";
import BacklogTaskCard from "./BacklogTaskCard";
import styled from "styled-components";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: "14px",
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    cursor: "pointer",
    height: "76px",
    padding: "15px 16px 12px 18px",
    border: "1px solid #f7f7f7",
    boxShadow: "1px 1px 4px #e5e9e6",

    "&:active": {
      cursor: "pointer",
    },

    "&:hover": {
      boxShadow: "1px 1px 4px 3px #e5e9e6",
    },
  },
  skeleton: {
    marginBottom: "14px",
    padding: "18px 18px 14px 21px",
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    height: "76px",
    boxShadow: "1px 1px 4px #e5e9e6",
    border: "1px dashed #00AEEE",
  },
}));

const DropZone = styled.div`
  margin: 40px;
  padding: 40px;
  background: transparent;
  border: 1px dashed #c0bfbf;
  text-align: center !important;
  content: "There is currently no Sprint in progress";
`;

const getListStyle = (isDraggingOver, isDragging) => ({
  margin: "0px",
  marginBottom: "50px",
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const getListContainerStyles = (snapshot) => {
  return {
    minHeight: "76px",
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    border: snapshot.isDraggingOver
      ? "1px dashed #afe2d2"
      : "1px dashed transparent",
    borderRadius: "7px",
  };
};

const listTopBarStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const DroppableTaskList = ({
  listId,
  isLoading,
  taskIds,
  tasks,
  title,
  selectTask,
  ActionBarComponent,
  NoDataComponent,
  containerStyles,
}) => {
  const classes = useStyles();

  return (
    <Droppable droppableId={listId}>
      {(provided, snapshot) => {
        const isDraggingOver = snapshot.isDraggingOver;
        const isDraggingFromThisWith = snapshot.draggingFromThisWith;
        const isUsingPlaceholder = snapshot.isUsingPlaceholder;
        const showNoDataPlaceholder =
          isUsingPlaceholder &&
          !!isDraggingFromThisWith &&
          taskIds.length === 1;

        return (
          <div style={{ ...containerStyles }}>
            <div style={listTopBarStyles}>
              <Typography
                variant="subtitle1"
                style={{
                  color: "#7E7E7E",
                  fontWeight: "bold",
                }}
              >
                {title.toUpperCase()}
              </Typography>
              <div id="actionBar">
                {ActionBarComponent && <ActionBarComponent />}
              </div>
            </div>

            <div style={getListStyle(snapshot.isDraggingOver)}>
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={getListContainerStyles(snapshot)}
              >
                {!isLoading && taskIds.length > 0 && tasks.length > 0 ? (
                  taskIds.map((taskId, index) => {
                    const task = tasks.find((t) => t.id === taskId);

                    return (
                      <Draggable
                        key={task.id}
                        draggableId={task.id}
                        index={index}
                      >
                        {(provided, snapshot) => {
                          return (
                            <Paper
                              ref={provided.innerRef}
                              onClick={() => selectTask(taskId)}
                              elevation={0}
                              classes={{
                                root: classes.card,
                              }}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                cursor: "pointer",
                              }}
                            >
                              <BacklogTaskCard task={task} />
                            </Paper>
                          );
                        }}
                      </Draggable>
                    );
                  })
                ) : NoDataComponent ? (
                  <NoDataComponent isDraggingOver={snapshot.isDraggingOver} />
                ) : (
                  <DropZone />
                )}
                {showNoDataPlaceholder ? (
                  <NoDataComponent isDraggingOver={isDraggingOver} />
                ) : (
                  provided.placeholder
                )}
              </div>
            </div>
          </div>
        );
      }}
    </Droppable>
  );
};

export default DroppableTaskList;
