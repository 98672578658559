import DocumentTitle from "react-document-title";

const Layout = ({ pageTitle = "Scrumist", rootPageStyles, children }) => {
  return (
    <div style={{ height: "100%", ...rootPageStyles }}>
      <DocumentTitle title={pageTitle} />
      {children}
    </div>
  );
};

export default Layout;
