import { initializeApp, FirebaseError } from "firebase/app";
import { getAuth } from "firebase/auth";
import { addDoc, collection, doc, getFirestore } from "firebase/firestore";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app, "us-east1");
let baseApiUrl = process.env.REACT_APP_FIREBASE_API_URL;

export const callFunction = (functionName, body) => {
  return httpsCallable(functions, functionName)(body);
};

if (
  typeof window !== "undefined" &&
  window.location.hostname === "localhost" &&
  process.env.NODE_ENV === "development"
) {
  connectFunctionsEmulator(functions, "localhost", 5001);
  baseApiUrl = `http://localhost:5001/scrumist-io-dev/us-east1/api`;
  window.FirebaseError = FirebaseError;
}

export const createApiUrl = (path) => `${baseApiUrl}${path}`;
