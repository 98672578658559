import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import { getTodoistProjects, connectProject } from "store/app/actions";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

const ConnectProjectModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [selectedProject, setSelectedProject] = useState(null);
  const error = useSelector((state) => state.user.formError);
  const projects = useSelector((state) => state.app.todoistProjects);

  const isConnectingProject = useSelector(
    (state) => state.app.isConnectingProject
  );

  useEffect(() => {
    if (!projects.length) {
      dispatch(getTodoistProjects());
    }
  }, [projects.length]);

  const filterItems = (query, item, _index, exactMatch) => {
    const normalizedTitle = item.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return `${item.id}. ${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
    }
  };

  const handleSelected = (project) => {
    setSelectedProject(project);
    handleConnect(project);
  };

  const handleConnect = (project) => {
    const projectId = project.id;
    const projectName = project.name;
    dispatch(connectProject({ projectId, projectName }));
  };

  //#region Commented out code
  // const setSelectedProject = (item) => {
  //   let _item = addCreatedProjectToArray(item);
  //   setSelectedProject(item);
  // };

  // const createProject = (name) => {
  //   // This is where we would trigger a `create` call to the API
  //   return {
  //     name: name,
  //     color: 0,
  //   };
  // };

  // const renderCreateProjectOption = (query, active, handleClick) => (
  //   <MenuItem
  //     icon="add"
  //     text={`Create Project: "${query}"`}
  //     active={active}
  //     onClick={handleClick}
  //     shouldDismissPopover={false}
  //   />
  // );

  // const addCreatedProjectToArray = (item) => {
  //   if (projects.filter((proj) => proj.name === item.name).length === 0) {
  //     setProjects([...projects, item]);
  //   }
  //   return item;
  // };
  //#endregion

  const classes = useStyles();

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Welcome to Scrumist!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select the project from your Todoist account that you would like to
          connect.
          {error && (
            <h4
              style={{
                color: "red",
              }}
            >
              {error}
            </h4>
          )}
        </DialogContentText>
      </DialogContent>
      <List>
        {projects.map((project) => {
          return (
            <ListItem
              key={project.id}
              name={project.id}
              onClick={() => handleSelected(project)}
              button
            >
              <ListItemText primary={project.name} />
            </ListItem>
          );
        })}
      </List>
    </Dialog>
  );
};

export default ConnectProjectModal;
