import { makeStyles } from "@material-ui/core/styles";
import Layout from "./Layout";
import {
  Container,
  Button,
  Typography,
  FormControl,
  FilledInput,
  InputLabel,
  SvgIcon,
  Link as MaterialLink,
  OutlinedInput,
  TextField,
  Box,
} from "@material-ui/core";

import { ReactComponent as MainLogo } from "assets/scrumist-logo-full.svg";
const drawerWidth = 275;

const useStyles = makeStyles((theme) => ({
  list: {
    paddingTop: "39px",
  },
  listIcon: {
    minWidth: 0,
    marginRight: "17px",
    "& > svg": {
      width: "30px",
      height: "30px",
    },
  },
  content: {
    flexGrow: 1,
    // color: "#fff",
    padding: "50px",
    // paddingLeft: `${drawerWidth + 50}px`,
  },
  pageName: {
    fontSize: "30px",
    fontWeight: "600",
    marginBottom: "34px",
  },
}));

const LayoutWithoutSidebar = ({ pageTitle, showNav, pageStyles, children }) => {
  const classes = useStyles();

  return (
    <Layout pageTitle={pageTitle} rootPageStyles={pageStyles}>
      <Container className={classes.root}>
        <SvgIcon
          viewBox="0 0 455 82"
          style={{
            display: "block",
            width: "400px",
            height: "175px",
            margin: "0 auto",
          }}
        >
          <MainLogo />
        </SvgIcon>
        <main className={classes.content}>{children}</main>
      </Container>
    </Layout>
  );
};

export default LayoutWithoutSidebar;
