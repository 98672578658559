import React from "react";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import store from "store";
import theme from "utilities/theme";
import App from "./App";
import Home from "./views";
import Login from "./views/login";
import PasswordReset from "./views/password-reset";
import ForgotPassword from "./views/forgot-password";
import SignUp from "./views/signup";
import Backlog from "./views/backlog";
import Projects from "./views/projects";
import LinkTodoist from "./views/link-todoist";
import ConnectProject from "./views/connect-project";
import SelectSubscription from "./views/select-subscription";
import Settings from "./views/settings";
import TodoistCallback from "./views/todoist-callback";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "./index.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

render(
  <Elements stripe={stripePromise}>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<App />}>
                <Route index element={<Home />} />
                <Route path="login" element={<Login />} />
                <Route path="password-reset" element={<PasswordReset />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="signup" element={<SignUp />} />
                <Route path="link-todoist" element={<LinkTodoist />} />
                <Route path="connect-project" element={<ConnectProject />} />
                <Route path="backlog" element={<Backlog />} />
                <Route path="projects" element={<Projects />} />
                <Route
                  path="select-subscription"
                  element={<SelectSubscription />}
                />
                <Route path="settings" element={<Settings />} />
                <Route path="todoist/callback" element={<TodoistCallback />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Provider>
  </Elements>,
  document.getElementById("root")
);
