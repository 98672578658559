import { useState } from "react";
import { useSelector } from "react-redux";
import { ProtectedPage, LayoutWithSidebar } from "layouts";
import { Button, Typography, MenuList, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useNavigate } from "react-router-dom";
import AddIcon from "@material-ui/icons/AddCircle";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  listProjects,
  setActiveProject,
  setDefaultProject,
  disconnectProject,
} from "store/app/actions";
import UpgradeModal from "modals/UpgradeModal";
import { callFunction } from "utilities/firebase";
import DisconnectConfirmationModal from "modals/DisconnectConfirmationModal";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  paper: {
    backgroundColor: "#ffffff",
    boxShadow: "1px 1px 4px 1px #e5e9e6",
  },
  header: {
    fontWeight: "bold",
    color: "#7E7E7E",
    textTransform: "uppercase",
  },
  icon: {
    width: "25px",
    height: "25px",
  },
  projectName: {
    cursor: "pointer",
    padding: "12px 25px 11px 12px",
    borderRadius: "5px",

    "&:hover": {
      color: "#00AEEE",
      backgroundColor: "#d9f3fc",
    },
  },
});

const ActionsMenu = ({
  targetId,
  anchorEl,
  isOpen,
  handleClose,
  handleSetAsDefault,
  handleDisconnect,
  isSetDefaultDisabled,
}) => {
  return (
    <Menu
      id={targetId}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      open={isOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <MenuList>
        <MenuItem disabled={isSetDefaultDisabled} onClick={handleSetAsDefault}>
          Set As Default
        </MenuItem>
        <MenuItem onClick={handleDisconnect}>Disconnect</MenuItem>
      </MenuList>
    </Menu>
  );
};

const ProjectsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorId, setAnchorId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [disconnectProjectId, setDisconnectProjectId] = useState(null);

  const openUpgradeModal = () => {
    setUpgradeModalOpen(true);
  };

  const closeUpgradeModal = () => {
    setUpgradeModalOpen(false);
  };

  const submitUpgradeModal = async () => {
    setIsLoading(true);

    try {
      const { data } = await callFunction(
        "ext-firestore-stripe-payments-createPortalLink",
        {
          returnUrl: `${window.location.origin}/settings`,
        }
      );
      window.location.assign(data.url);
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  const activeProjectCount = useSelector(
    (state) => state.user.activeProjectCount
  );
  const activeProjectCountAllowed = useSelector(
    (state) => state.user.activeProjectCountAllowed
  );

  const handleActionsOpen = (projectId) => (event) => {
    setAnchorEl(event.currentTarget);
    setAnchorId(projectId);
  };

  const handleActionsClose = () => {
    setAnchorId(null);
    setAnchorEl(null);
  };

  const handleConnectProjectClick = () => {
    if (activeProjectCount >= activeProjectCountAllowed) {
      openUpgradeModal();
    } else {
      navigate("/connect-project");
    }
  };

  const PageActionComponent = () => {
    return (
      <Box display="flex" alignItems="center">
        <Typography variant="h6" style={{ marginRight: "25px" }}>
          {activeProjectCount} / {activeProjectCountAllowed} Active Projects
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disableRipple
          onClick={handleConnectProjectClick}
          startIcon={<AddIcon className={classes.icon} />}
        >
          Connect Project
        </Button>
      </Box>
    );
  };

  const handleProjectNameClick = (projectId) => (event) => {
    // Set active project
    dispatch(setActiveProject({ projectId }));

    // Navigate to active sprint page
    navigate("/");
  };

  const handleSetAsDefault = (projectId) => () => {
    handleActionsClose();
    dispatch(setDefaultProject({ projectId }));
  };

  const handleDisconnect = (projectId) => () => {
    handleActionsClose();
    setDisconnectProjectId(projectId);
  };

  const closeDisconnectConfirmationModal = (projectId) =>
    setDisconnectProjectId(null);

  const submitDisconnectConfirmationModal = () => {
    dispatch(disconnectProject({ projectId: disconnectProjectId }));
    setDisconnectProjectId(null);
  };

  const isLoggedIn = useSelector((state) => state.user.loggedIn);
  const projects = useSelector((state) => state.app.projects);
  useEffect(() => {
    if (isLoggedIn && !projects.length) {
      dispatch(listProjects());
    }
  }, [isLoggedIn, projects.length, dispatch]);

  return (
    <ProtectedPage isLoading={isLoading}>
      <LayoutWithSidebar
        PageActionComponent={PageActionComponent}
        showProjectName={false}
      >
        <TableContainer
          component={Paper}
          elevation={0}
          classes={{ root: classes.paper }}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="body2"
                    classes={{ root: classes.header }}
                  >
                    Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    classes={{ root: classes.header }}
                  >
                    Type
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    classes={{ root: classes.header }}
                  >
                    Default
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project) => (
                <TableRow key={project.id} style={{ marginBottom: "15px" }}>
                  <TableCell
                    scope="row"
                    onClick={handleProjectNameClick(project.id)}
                  >
                    <Typography
                      variant="subtitle2"
                      classes={{ root: classes.projectName }}
                    >
                      {project.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{project.type}</Typography>
                  </TableCell>
                  <TableCell>{project.isDefault ? "yes" : "no"}</TableCell>
                  <TableCell style={{ width: "100px" }}>
                    <IconButton
                      aria-label="more"
                      aria-controls={`long-menu-${project.id}`}
                      aria-haspopup="true"
                      style={{ padding: "5px" }}
                      onClick={handleActionsOpen(project.id)}
                    >
                      <MoreVertIcon style={{ width: "28px", height: "28px" }} />
                    </IconButton>
                    <ActionsMenu
                      targetId={`long-menu-${project.id}`}
                      anchorEl={anchorEl}
                      isOpen={anchorId === project.id}
                      handleClose={handleActionsClose}
                      handleSetAsDefault={handleSetAsDefault(project.id)}
                      handleDisconnect={handleDisconnect(project.id)}
                      isSetDefaultDisabled={project.isDefault}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <UpgradeModal
          isOpen={isUpgradeModalOpen}
          handleClose={closeUpgradeModal}
          handleSubmit={submitUpgradeModal}
        />
        <DisconnectConfirmationModal
          isOpen={!!disconnectProjectId}
          handleClose={closeDisconnectConfirmationModal}
          handleSubmit={submitDisconnectConfirmationModal}
        />
      </LayoutWithSidebar>
    </ProtectedPage>
  );
};
export default ProjectsPage;
