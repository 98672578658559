import { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { ProtectedPage, LayoutWithSidebar } from "layouts";
import { Divider, Button, Typography } from "@material-ui/core";
import ChangeEmailModal from "modals/ChangeEmailModal";
import ChangePasswordModal from "modals/ChangePasswordModal";
import UnlinkTodoistConfirmationModal from "modals/UnlinkTodoistConfirmationModal";
import { callFunction } from "utilities/firebase";

const SettingsGroup = ({ position, title, children }) => {
  return (
    <Fragment>
      {position === "top" && <Divider />}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "25px",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <div style={{ flexGrow: "1", marginLeft: "150px" }}>{children}</div>
      </div>
      <Divider />
    </Fragment>
  );
};

const SettingsSection = ({
  label,
  value,
  disabled = false,
  actionText,
  actionHandler,
}) => {
  return (
    <div
      style={{
        display: "flex",
        marginBottom: "30px",
        marginTop: "30px",
        alignItems: !value ? "center" : "initial",
      }}
    >
      <Typography
        variant="subtitle2"
        style={{
          marginRight: "35px",
          textAlign: "right",
          width: "175px",
        }}
      >
        {label}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {value && <div style={{ marginBottom: "8px" }}>{value}</div>}

        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={actionHandler}
          disabled={disabled}
          disableRipple
        >
          {actionText}
        </Button>
      </div>
    </div>
  );
};

const getSubscriptionText = (
  status,
  endDate,
  trialEndDate,
  cancelAtPeriodEnd
) => {
  switch (status) {
    case "active":
      if (cancelAtPeriodEnd) {
        return `Current plan ends on ${endDate}.`;
      } else {
        return `Current plan auto renews on ${endDate}.`;
      }

    case "trialing":
      return `Trial ends on ${trialEndDate}.`;

    case "canceled":
      return `Subscription has been canceled.`;

    default:
      return "";
  }
};

const AccountSettingsPage = () => {
  // const email = useSelector((state) => state.user.email);
  const hasSubscription = useSelector((state) => state.user.hasSubscription);
  const subscriptionStatus = useSelector(
    (state) => state.user.subscriptionStatus
  );
  const subscriptionPeriodEnd = useSelector(
    (state) => state.user.subscriptionPeriodEnd
  );
  const subscriptionTrialPeriodEnd = useSelector(
    (state) => state.user.subscriptionTrialPeriodEnd
  );
  const subscriptionCancelAtPeriodEnd = useSelector(
    (state) => state.user.subscriptionCancelAtPeriodEnd
  );
  const accessToken = useSelector((state) => state.user.todoistAccessToken);

  const [isLoading, setIsLoading] = useState(false);
  const [isChangeEmailModalOpen, setChangeEmailModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState(false);
  const [isUnlinkTodoistModalOpen, setUnlinkTodoistModalOpen] = useState(false);

  const handleManageSubscriptionClick = async () => {
    setIsLoading(true);

    try {
      const { data } = await callFunction(
        "ext-firestore-stripe-payments-createPortalLink",
        {
          returnUrl: `${window.location.origin}/settings`,
        }
      );
      window.location.assign(data.url);
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  const subscriptionText = hasSubscription
    ? getSubscriptionText(
        subscriptionStatus,
        subscriptionPeriodEnd,
        subscriptionTrialPeriodEnd,
        subscriptionCancelAtPeriodEnd
      )
    : "";

  return (
    <ProtectedPage isLoading={isLoading}>
      <LayoutWithSidebar showProjectName={false}>
        {/* <SettingsGroup title="Account" position="top">
          <SettingsSection
            label="Email"
            value={email}
            actionText="Change Email Address"
            actionHandler={() => setChangeEmailModalOpen(true)}
          />
          <SettingsSection
            label="Password"
            value="*****************************"
            actionText="Change Password"
            actionHandler={() => setChangePasswordModalOpen(true)}
          />
        </SettingsGroup> */}

        <SettingsGroup title="Subscription">
          <SettingsSection
            label="Manage Subscription"
            value={subscriptionText}
            actionText="Visit Subscription Portal"
            actionHandler={handleManageSubscriptionClick}
            disabled={!hasSubscription}
          />
        </SettingsGroup>

        <SettingsGroup title="Integrations">
          <SettingsSection
            label="Todoist Account"
            value=""
            actionText="Unlink Account"
            actionHandler={() => setUnlinkTodoistModalOpen(true)}
            disabled={!accessToken}
          />
        </SettingsGroup>

        <ChangeEmailModal
          isOpen={isChangeEmailModalOpen}
          handleClose={() => setChangeEmailModalOpen(false)}
        />
        <ChangePasswordModal
          isOpen={isChangePasswordModalOpen}
          handleClose={() => setChangePasswordModalOpen(false)}
        />
        <UnlinkTodoistConfirmationModal
          isOpen={isUnlinkTodoistModalOpen}
          handleClose={() => setUnlinkTodoistModalOpen(false)}
        />
      </LayoutWithSidebar>
    </ProtectedPage>
  );
};
export default AccountSettingsPage;
