import { useState } from "react";
import {
  Button,
  Typography,
  Container,
  SvgIcon,
  TextField,
  Box,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Layout } from "layouts";
import { createApiUrl } from "utilities/firebase";
import { ReactComponent as MainLogo } from "assets/scrumist-logo-full.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { findApiError } from "utilities";

const getOS = () => {
  let OSName = "Unknown OS";
  if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
  if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
  if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
  if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";

  return OSName;
};

const getBrowser = () => {
  let nVer = navigator.appVersion;
  let nAgt = navigator.userAgent;
  let browserName = navigator.appName;
  let fullVersion = "" + parseFloat(navigator.appVersion);
  let majorVersion = parseInt(navigator.appVersion, 10);
  let nameOffset, verOffset, ix;

  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = nAgt.indexOf("Opera")) != -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf("Version")) != -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) != -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if (
    (nameOffset = nAgt.lastIndexOf(" ") + 1) <
    (verOffset = nAgt.lastIndexOf("/"))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(";")) != -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(" ")) != -1)
    fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt("" + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = "" + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  return browserName;
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "450px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  paper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "80px",
    alignItems: "center",
  },
  noBorder: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { value } = e.target;
    e.preventDefault();

    setEmail(value);
  };

  const handleFormSubmit = async () => {
    setError("");
    setIsLoading(true);

    try {
      const url = createApiUrl(`/auth/passwordReset`);
      const body = {
        email,
        operatingSystem: getOS(),
        browser: getBrowser(),
      };
      await axios.post(url, body);

      navigate("/login");
    } catch (error) {
      setError(findApiError(error));
      setIsLoading(false);
    }
  };

  return (
    <Layout pageTitle={"Forgot Password"} showNav={false}>
      <Paper elevation={0} classes={{ root: classes.paper }}>
        <Container className={classes.root}>
          <SvgIcon
            viewBox="0 0 455 82"
            style={{
              display: "block",
              width: "400px",
              height: "175px",
              margin: "0 auto",
            }}
          >
            <MainLogo />
          </SvgIcon>
          <Typography variant="h1" align="center" gutterBottom>
            Reset your password
          </Typography>
          <Typography variant="subtitle1" align="center" gutterBottom>
            Please enter the email address for your account.
          </Typography>

          <fieldset
            style={{
              opacity: isLoading ? ".3" : 1,
              border: "none",
            }}
          >
            <TextField
              id="email"
              name="email"
              variant="outlined"
              margin="normal"
              label="Email Address"
              type="email"
              fullWidth
              placeholder="john.doe@scrumist.io"
              onChange={handleInputChange}
              value={email}
              required
              InputProps={{
                classes: {
                  root: classes.noBorder,
                },
              }}
            />
          </fieldset>

          <Box width="100%" mt={2}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleFormSubmit}
              style={{ display: "block", width: "100%" }}
              disableRipple
              disabled={
                !email.includes("@") ||
                !email.split("@")[1]?.includes(".") ||
                isLoading
              }
            >
              Send Reset Email
            </Button>
          </Box>
        </Container>
      </Paper>
    </Layout>
  );
};

export default ForgotPassword;
