import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const useCheckOnboardingState = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const onConnectProjectPage = location.pathname === "/connect-project";
  const isDoneConnectingNewProject = location.state?.isDoneConnectingProject;
  const isAppReady = useSelector((state) => state.app.isInitialized);
  const hasSubscription = useSelector((state) => state.user.hasSubscription);
  const accessToken = useSelector((state) => state.user.todoistAccessToken);
  const defaultProjectId = useSelector((state) => state.user.defaultProjectId);

  useEffect(() => {
    // If user hasn't yet selected a subscription
    if (isAppReady && !hasSubscription) {
      navigate("/select-subscription", { replace: true });

      // If user hasn't linked Todoist account show that modal
    } else if (isAppReady && hasSubscription && !accessToken) {
      navigate("/link-todoist", { replace: true });

      // If the user has linked their Todoist account but hasn't connected a project
    } else if (
      isAppReady &&
      hasSubscription &&
      !!accessToken &&
      !defaultProjectId
    ) {
      navigate("/connect-project", { replace: true });
    } else if (onConnectProjectPage && isDoneConnectingNewProject) {
      navigate("/backlog", { replace: true });
    }
  }, [
    isAppReady,
    hasSubscription,
    accessToken,
    defaultProjectId,
    onConnectProjectPage,
    isDoneConnectingNewProject,
    navigate,
  ]);

  return {
    isLoading: true,
    hasFinishedOnboarding: false,
  };
};

export default useCheckOnboardingState;
