import { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Typography,
  MenuItem,
  Box,
  SvgIcon,
  TextField,
  Paper,
} from "@material-ui/core";
import { ProtectedPage, Layout } from "layouts";
import { getTodoistProjects, connectProject } from "store/app/actions";
import { useCheckOnboardingState } from "hooks";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as MainLogo } from "assets/scrumist-logo-full.svg";
import NavBar from "layouts/Navbar";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100% - 106px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "80px 200px",
    paddingBottom: "0px",
    alignItems: "center",
  },
  grid: {
    marginTop: "10px",
    marginBottom: "35px",
  },
  card: {
    width: "400px",
    marginBottom: "15px",
    "&:hover": {
      boxShadow:
        "-4px 6px 6px -3px rgb(60 186 149 / 60%), 0px 10px 14px 1px rgb(60 186 149 / 42%), 0px 4px 18px 3px rgb(60 186 149 / 36%)",
    },
  },
  cardHeader: ({ highlightColor }) => {
    return {
      backgroundColor: highlightColor,
    };
  },
  cardActions: {
    justifyContent: "center",
    marginBottom: "25px",
  },
  select: {
    maxWidth: "500px",
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.background,
    padding: "6px 12px",
    borderRadius: "5px",
  },
  noBorder: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
}));

const ConnectProject = () => {
  useCheckOnboardingState();

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAppReady = useSelector((state) => state.app.isInitialized);
  const projects = useSelector((state) => state.app.todoistProjects);
  const isConnectingProject = useSelector(
    (state) => state.app.isConnectProject
  );

  const [isLoading, setIsLoading] = useState(true);
  const [hasFetchedProjects, setHasFetchedProjects] = useState(false);
  const [selectedProject, selectProject] = useState("");
  const [error, setError] = useState("");

  const handleConnectProject = async () => {
    const { id: projectId, name: projectName } = selectedProject;
    setError("");
    const onSuccess = () =>
      navigate("/connect-project", {
        state: {
          isDoneConnectingProject: true,
        },
      });
    const onError = (error) => {
      setError(error);
    };
    dispatch(connectProject({ projectId, projectName, onSuccess, onError }));
  };

  useEffect(() => {
    if (isAppReady && !projects.length && !hasFetchedProjects) {
      setIsLoading(true);
      setHasFetchedProjects(true);
      dispatch(
        getTodoistProjects({
          onSuccess: () => setIsLoading(false),
          onError: (error) => setError(error),
        })
      );
    }

    if (projects.length) {
      setIsLoading(false);
    }

    if ((!projects.length, hasFetchedProjects && error)) {
      setIsLoading(false);
    }
  }, [isAppReady, hasFetchedProjects, error, projects.length, dispatch]);

  return (
    <ProtectedPage isLoading={isLoading}>
      <Layout>
        <NavBar transparent={true} showProjectName={false} />
        <Paper elevation={0} classes={{ root: classes.root }}>
          <SvgIcon
            viewBox="0 0 455 82"
            style={{
              display: "block",
              width: "400px",
              height: "175px",
              margin: "0 auto",
            }}
          >
            <MainLogo />
          </SvgIcon>

          <Typography variant="h1" align="center" gutterBottom>
            Connect Project
          </Typography>
          <Typography variant="subtitle1" align="center" gutterBottom>
            Select the project from your Todoist account that you wish to
            connect.
          </Typography>

          {error ? (
            <Typography
              variant="subtitle2"
              align="center"
              classes={{ root: classes.error }}
              gutterBottom
            >
              {error}
            </Typography>
          ) : (
            <Fragment>
              <fieldset
                style={{
                  opacity: isConnectingProject ? ".3" : 1,
                  border: "none",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  label="Project"
                  margin="normal"
                  value={selectedProject}
                  onChange={(event) => {
                    const { value } = event.target;
                    selectProject(value);
                  }}
                  renderValue={(project) => {
                    if (typeof project === "string") {
                      return "Select a Project...";
                    }
                    return project.name;
                  }}
                  displayEmpty={true}
                  InputProps={{
                    classes: {
                      root: classes.noBorder,
                    },
                  }}
                  classes={{
                    root: classes.select,
                  }}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    },
                  }}
                >
                  {projects.map((project) => {
                    return (
                      <MenuItem key={project.id} value={project}>
                        {project.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </fieldset>

              <Box
                display="flex"
                mt={2}
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disableRipple
                  disabled={!selectedProject || isConnectingProject}
                  onClick={handleConnectProject}
                >
                  Let's Get Started
                </Button>
              </Box>
            </Fragment>
          )}
        </Paper>
      </Layout>
    </ProtectedPage>
  );
};

export default ConnectProject;
