import { Button, Typography, Box, SvgIcon, Paper } from "@material-ui/core";
import { ProtectedPage } from "layouts";
import { useCheckOnboardingState } from "hooks";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as MainLogo } from "assets/scrumist-logo-full.svg";
import NavBar from "layouts/Navbar";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100% - 106px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "80px 200px",
    paddingTop: "0px",
    alignItems: "center",
  },
  grid: {
    marginTop: "10px",
    marginBottom: "35px",
  },
  card: {
    width: "400px",
    marginBottom: "15px",
    "&:hover": {
      boxShadow:
        "-4px 6px 6px -3px rgb(60 186 149 / 60%), 0px 10px 14px 1px rgb(60 186 149 / 42%), 0px 4px 18px 3px rgb(60 186 149 / 36%)",
    },
  },
  cardHeader: ({ highlightColor }) => {
    return {
      backgroundColor: highlightColor,
    };
  },
  cardActions: {
    justifyContent: "center",
    marginBottom: "25px",
  },
  select: {
    maxWidth: "500px",
  },
  noBorder: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
}));

const LinkTodoist = () => {
  useCheckOnboardingState();
  const classes = useStyles();

  const handleClickLinkTodoistButton = () => {
    const clientId = process.env.REACT_APP_TODOIST_CLIENT_ID;
    const state = process.env.REACT_APP_TODOIST_STATE;

    window.location.assign(
      `https://todoist.com/oauth/authorize?client_id=${clientId}&scope=data:read_write,data:delete&state=${state}`
    );
  };

  return (
    <ProtectedPage>
      <NavBar transparent={true} showProjectName={false} />
      <Paper elevation={0} classes={{ root: classes.root }}>
        <SvgIcon
          viewBox="0 0 455 82"
          style={{
            display: "block",
            width: "400px",
            height: "175px",
            margin: "0 auto",
          }}
        >
          <MainLogo />
        </SvgIcon>
        <Typography variant="h1" align="center" gutterBottom>
          Welcome to Scrumist
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          Next Step: Link your account with Todoist
        </Typography>

        <Box display="flex" mt={2} alignItems="center" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableRipple
            onClick={handleClickLinkTodoistButton}
          >
            Link Todoist
          </Button>
        </Box>
      </Paper>
    </ProtectedPage>
  );
};

export default LinkTodoist;
