import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  MenuItem,
  MenuList,
  Dialog,
  Avatar,
  Typography,
  Box,
} from "@material-ui/core";
import { signUserOut } from "store/user/actions";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: "106px",
    padding: "28px 32px 28px 30px",
    borderBottom: "1px solid #eaeaea",
    backgroundColor: "transparent",
  },
  appBarTransparent: {
    height: "106px",
    padding: "28px 32px 28px 30px",
    borderBottom: "1px solid transparent",
    backgroundColor: "#f9fdfb",
  },
  toolbar: {
    height: "100%",
    width: "100%",
    padding: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  avatar: {
    cursor: "pointer",
    width: "49px",
    height: "49px",
  },
  custom: {
    "& .MuiPaper-root": {
      position: "absolute",
      right: "-5px",
      top: "60px",
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  },
}));

const SettingsCard = ({ isOpen, handleClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleSettingsClick = () => {
    handleClose();
    navigate("/settings");
  };

  const handleLogOutClick = () => {
    handleClose();
    dispatch(signUserOut());
  };

  return isOpen ? (
    <Dialog
      id="settings-menu"
      className={classes.custom}
      open={isOpen}
      onClose={handleClose}
    >
      <MenuList>
        <MenuItem onClick={handleSettingsClick}>Settings</MenuItem>
        <MenuItem onClick={handleLogOutClick}>Log Out</MenuItem>
      </MenuList>
    </Dialog>
  ) : null;
};

const NavBar = ({
  pageName,
  PageActionComponent,
  showProjectName = true,
  transparent = false,
}) => {
  const classes = useStyles();

  const displayName = useSelector((state) => state.user.todoistUser.full_name);
  const photoUrl = useSelector((state) => state.user.todoistUser.avatar_big);
  const projectName = useSelector((state) => state.app.activeProject.name);

  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);

  const handleSettingsOpen = () => {
    setSettingsModalOpen(true);
  };
  const handleSettingsClose = () => {
    setSettingsModalOpen(false);
  };

  return (
    <AppBar
      position="relative"
      elevation={0}
      classes={{
        root: transparent ? classes.appBarTransparent : classes.appBar,
      }}
    >
      <Toolbar classes={{ root: classes.toolbar }}>
        <Box display="flex" alignItems="center">
          <Typography variant="h1" style={{ marginRight: "16px" }}>
            {pageName}
          </Typography>
          {showProjectName && projectName && (
            <Typography
              variant="h5"
              color="secondary"
              style={{
                padding: "7px 10px",
                backgroundColor: "#d4f1f9",
                borderRadius: "5px",
              }}
            >
              Project: {projectName}
            </Typography>
          )}
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "82px" }}>
            {PageActionComponent && <PageActionComponent />}
          </div>

          <Avatar
            classes={{ root: classes.avatar }}
            alt={displayName}
            src={photoUrl}
            onClick={handleSettingsOpen}
          />
          <SettingsCard
            isOpen={isSettingsModalOpen}
            handleClose={handleSettingsClose}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
