import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputLabel,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  Box,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import CloseIcon from "@material-ui/icons/Close";
import FlagIcon from "@material-ui/icons/Flag";
import { createTask, updateTask } from "store/app/actions";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: "1000px",
    margin: "0 auto",
    padding: "30px",
    height: "90%",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "0px",
    marginBottom: "15px",
  },
  titleIcon: {
    color: " #00C99A",
    height: "26px",
    width: "26px",
    marginRight: "8px",
  },
  closeIcon: {
    height: "28px",
    width: "28px",
    cursor: "pointer",

    "&:hover": {
      color: "#616362",
    },
  },
  titleText: {},
  content: {
    overflowY: "initial",
    padding: "0px",
  },
  actions: {},
  error: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.background,
    padding: "6px 12px",
    borderRadius: "5px",
  },
  noBorder: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
}));

const getPriorityColor = (priority) => {
  switch (priority) {
    case 1:
      return "rgba(63,61,86,0.3)";

    case 2:
      return "#15B2E6";

    case 3:
      return "#FCC840";

    case 4:
      return "#FC4040";

    default:
      return null;
  }
};

const usePriorityStyles = makeStyles({
  container: {
    height: "100%",
    paddingTop: "18px",
    "&:hover > svg": {
      cursor: "pointer",
      backgroundColor: "#fff",
    },
    "& svg:hover": {
      cursor: "pointer",
      backgroundColor: "#ebebeb",
    },
  },
  flag: (props) => {
    return {
      height: "36px",
      width: "36px",
      marginRight: "8px",
      borderRadius: "100%",
      padding: "6px",
      color: getPriorityColor(props.value),
      backgroundColor:
        props.value === props.selectedPriority ? "#ebebeb" : "#fff",
    };
  },
});

const TaskForm = ({
  draftTask,
  handleTextFieldChange,
  handlePriorityChange,
  handleStatusChange,
  handleStoryPointsChange,
  handleDateChange,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={9}>
        <TextField
          id="title"
          name="title"
          label="Task Title"
          variant="outlined"
          margin="normal"
          multiline
          maxRows={5}
          fullWidth
          required
          helperText={`${draftTask?.title.length || 0} / 255 characters used`}
          value={draftTask?.title || ""}
          onChange={handleTextFieldChange}
          InputProps={{
            classes: {
              root: classes.noBorder,
            },
          }}
        />
        <TextField
          id="description"
          name="description"
          label="Description"
          variant="outlined"
          margin="normal"
          fullWidth
          multiline
          minRows={6}
          maxRows={20}
          value={draftTask?.description || ""}
          onChange={handleTextFieldChange}
          InputProps={{
            classes: {
              root: classes.noBorder,
            },
          }}
        />
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <KeyboardDatePicker
              autoOk
              id="dueDate"
              name="dueDate"
              variant="inline"
              margin="normal"
              fullWidth
              inputVariant="outlined"
              label="Due Date"
              format="MM/DD/yyyy"
              InputProps={{
                classes: {
                  root: classes.noBorder,
                },
              }}
              InputAdornmentProps={{ position: "end" }}
              KeyboardButtonProps={{
                color: "primary",
                size: "small",
              }}
              value={draftTask?.dueDate || null}
              onChange={(date) => handleDateChange("dueDate", date)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" style={{ height: "100%" }}>
              <InputLabel htmlFor="priority" shrink={true}>
                Priority
              </InputLabel>
              <div className={usePriorityStyles().container}>
                <FlagIcon
                  className={
                    usePriorityStyles({
                      value: 1,
                      selectedPriority: draftTask?.priority || 1,
                    }).flag
                  }
                  onClick={() => handlePriorityChange(1)}
                />
                <FlagIcon
                  className={
                    usePriorityStyles({
                      value: 2,
                      selectedPriority: draftTask?.priority || 1,
                    }).flag
                  }
                  onClick={() => handlePriorityChange(2)}
                />
                <FlagIcon
                  className={
                    usePriorityStyles({
                      value: 3,
                      selectedPriority: draftTask?.priority || 1,
                    }).flag
                  }
                  onClick={() => handlePriorityChange(3)}
                />
                <FlagIcon
                  className={
                    usePriorityStyles({
                      value: 4,
                      selectedPriority: draftTask?.priority || 1,
                    }).flag
                  }
                  onClick={() => handlePriorityChange(4)}
                />
              </div>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <TextField
          select
          variant="outlined"
          label="Status"
          margin="normal"
          value={draftTask?.status || "To Do"}
          onChange={handleStatusChange}
          fullWidth
          InputProps={{
            classes: {
              root: classes.noBorder,
            },
          }}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            },
          }}
        >
          <MenuItem value={"To Do"}>To Do</MenuItem>
          <MenuItem value={"Blocked"}>Blocked</MenuItem>
          <MenuItem value={"In Progress"}>In Progress</MenuItem>
          <MenuItem value={"Done"}>Done</MenuItem>
        </TextField>
        <TextField
          type="number"
          id="storyPoints"
          name="storyPoints"
          label="Story Points"
          variant="outlined"
          margin="normal"
          fullWidth
          InputProps={{
            classes: {
              root: classes.noBorder,
            },
          }}
          inputProps={{ min: "0", max: "99" }}
          value={String(draftTask?.storyPoints) || "-"}
          onChange={handleStoryPointsChange}
        />
      </Grid>
    </Grid>
  );
};

const defaultTaskState = {
  title: "",
  description: "",
  dueDate: moment().format("YYYY-MM-DD"),
  priority: 1,
  status: "To Do",
};

const TaskModal = ({ isOpen, handleClose, task }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const modalType = task ? "Edit" : "Create";
  const modalTitle = modalType === "Create" ? "Create Task" : "Edit Task";
  const modalActionButtonText = modalType === "Create" ? "Create" : "Update";
  const error = useSelector((state) => state.app.error);
  const isLoading = useSelector((state) => state.app.isFormLoading);

  const [draftTask, updateDraftTask] = useState(task ? task : defaultTaskState);

  useEffect(() => {
    if (task) {
      updateDraftTask(task);
    } else {
      updateDraftTask(defaultTaskState);
    }
  }, [task]);

  const handleTextFieldChange = (e) => {
    if (e.target.name === "title") {
      const trimmedValue =
        e.target.value.length >= 255
          ? e.target.value.substr(0, 255)
          : e.target.value;

      updateDraftTask({
        ...draftTask,
        [e.target.name]: trimmedValue,
      });
    } else {
      updateDraftTask({
        ...draftTask,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleDateChange = (name, date) => {
    updateDraftTask({
      ...draftTask,
      [name]: date,
    });
  };

  const handlePriorityChange = (priority) => {
    updateDraftTask({
      ...draftTask,
      priority,
    });
  };

  const handleStatusChange = (e) => {
    updateDraftTask({
      ...draftTask,
      status: e.target.value,
    });
  };

  const handleStoryPointsChange = (e) => {
    // Add temporary validation to prevent points >= 100 (3 digits)
    const draftValue = Number(e.target.value);
    const storyPoints = draftValue >= 100 ? draftTask.storyPoints : draftValue;

    updateDraftTask({
      ...draftTask,
      storyPoints,
    });
  };

  const handleUpdateTask = () => {
    const { id, ...updates } = draftTask;
    const { dueDate } = updates;
    const updateMap = {
      ...updates,
      dueDate: dueDate ? moment(dueDate).format("YYYY-MM-DD") : "",
    };

    dispatch(updateTask({ taskId: id, updateMap, closeModal: handleClose }));
  };

  const handleCreateTask = () => {
    const { dueDate } = draftTask;
    const newTask = {
      ...draftTask,
      dueDate: dueDate ? moment(dueDate).format("YYYY-MM-DD") : "",
    };

    dispatch(createTask({ newTask, closeModal: handleClose }));
  };

  const handleSubmitAction =
    modalType === "Create" ? handleCreateTask : handleUpdateTask;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
      classes={{ paper: classes.paper }}
      aria-labelledby="Task-Modal"
    >
      <DialogTitle id={modalTitle} className={classes.title} disableTypography>
        <Box display="flex" alignItems="center" width="100%">
          <CreateIcon className={classes.titleIcon} />
          <Typography variant="h4" className={classes.titleText}>
            {modalTitle}
          </Typography>
        </Box>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {error && (
          <Typography
            variant="subtitle2"
            align="left"
            classes={{ root: classes.error }}
            gutterBottom
          >
            {error}
          </Typography>
        )}
        <fieldset
          style={{
            opacity: isLoading ? ".3" : 1,
            border: "none",
          }}
        >
          <TaskForm
            draftTask={draftTask}
            handleTextFieldChange={handleTextFieldChange}
            handlePriorityChange={handlePriorityChange}
            handleStatusChange={handleStatusChange}
            handleStoryPointsChange={handleStoryPointsChange}
            handleDateChange={handleDateChange}
          />
        </fieldset>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          disableRipple
          style={{
            boxSizing: "border-box",
            width: "75px",
          }}
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disableRipple
          style={{
            boxSizing: "border-box",
            width: "75px",
          }}
          onClick={handleSubmitAction}
          disabled={isLoading}
        >
          {modalActionButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaskModal;
