import { useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Button, Typography, Box } from "@material-ui/core";

import { ProtectedPage, LayoutWithSidebar } from "layouts";
import NoDataPaperCard from "components/NoDataPaperCard";
import Board from "components/Board";
import StatusColumn from "components/StatusColumn";
import { TaskModal, SprintCompleteConfirmationModal } from "modals";
import {
  updateTask,
  updateSprintRanks,
  updateSprintTaskRankAndStatus,
  clearError,
  getProject,
} from "store/app/actions";
import { useCheckOnboardingState } from "hooks";
import { useEffect } from "react";

const Home = () => {
  useCheckOnboardingState();

  const dispatch = useDispatch();
  const tasks = useSelector((state) => state.app.activeProject.tasks);
  const activeSprintId = useSelector(
    (state) => state.app.activeProject.activeSprintId
  );
  const sprint = useSelector((state) => state.app.sprint);
  const sprintTaskIds = useSelector(
    (state) => state.app.activeProject.sprintTaskIds
  );
  const isLoading = useSelector((state) => state.app.isFetchingProject);

  const sprintTasks = sprintTaskIds.map((taskId) =>
    tasks.find((t) => t.id === taskId)
  );
  const toDoTasks = sprintTasks.filter((t) => t.status === "To Do");
  const blockedTasks = sprintTasks.filter((t) => t.status === "Blocked");
  const inProgressTasks = sprintTasks.filter((t) => t.status === "In Progress");
  const doneTasks = sprintTasks.filter((t) => t.status === "Done");

  const [isTaskModalOpen, setTaskModalOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [isSprintConfirmationModalOpen, setSprintConfirmationModalOpen] =
    useState(false);

  const openSprintConfirmationModal = () =>
    setSprintConfirmationModalOpen(true);

  const closeSprintConfirmationModal = () =>
    setSprintConfirmationModalOpen(false);

  const openTaskModal = () => setTaskModalOpen(true);

  const handleCardClick = (taskId) => {
    const task = tasks.find((t) => t.id === taskId);
    if (task) {
      setCurrentTask(task);
      openTaskModal();
    }
  };

  const clearTask = () => setCurrentTask(null);

  const closeTaskModal = () => {
    setTaskModalOpen(false);
    dispatch(clearError());
    setTimeout(() => clearTask(), 500);
  };

  const SprintPageActionComponent = () => {
    if (activeSprintId && sprint.status !== "Created" && !isLoading) {
      const shouldDisplayDaysRemaining = !!sprint.endDate;
      const endStartDiff = moment(sprint.endDate).diff(moment(), "days");
      const daysRemaining = endStartDiff <= 0 ? 0 : endStartDiff;
      const daysRemainingText =
        daysRemaining === 1
          ? `${daysRemaining} day remaining`
          : `${daysRemaining} days remaining`;

      return (
        <Box display="flex" alignItems="center">
          {shouldDisplayDaysRemaining && (
            <Typography variant="h6" style={{ marginRight: "25px" }}>
              {daysRemainingText}
            </Typography>
          )}

          <Button
            variant="contained"
            color="primary"
            size="medium"
            disableRipple
            style={{ textTransform: "none" }}
            onClick={openSprintConfirmationModal}
          >
            Complete Sprint
          </Button>
        </Box>
      );
    }

    return null;
  };

  const getTasksForStatus = (status) => {
    switch (status) {
      case "To Do":
        return toDoTasks;

      case "Blocked":
        return blockedTasks;

      case "In Progress":
        return inProgressTasks;

      case "Done":
        return doneTasks;

      default:
        return;
    }
  };

  const handleDrop = (props) => {
    const { destination, source } = props;
    // If not dropped into a droppable container, don't make any updates
    if (!destination) return;

    // If we are updating the current ranking within this column
    if (destination.droppableId === source.droppableId) {
      // More complex indexing here because we need to maintain the ranking of tasks for the entire sprint
      // but also re-arrange the subset status updated here
      const sourceStatusTasks = getTasksForStatus(source.droppableId);
      const sourceId = sourceStatusTasks[source.index].id;
      const destinationStatusTasks = getTasksForStatus(destination.droppableId);
      const destinationId = destinationStatusTasks[destination.index].id;
      const destinationIndex = sprintTaskIds.indexOf(destinationId);

      // We need to remove the taskId we want to re-arrange before we slice the sprintTaskIds together
      const filteredSprintTaskIds = sprintTaskIds.filter(
        (id) => id !== sourceId
      );
      const reorderedTaskIds = [
        ...filteredSprintTaskIds.slice(0, destinationIndex),
        sourceId,
        ...filteredSprintTaskIds.slice(destinationIndex),
      ];

      dispatch(updateSprintRanks({ sprintTaskIds: reorderedTaskIds }));
    } else {
      // If we are updating the status / rank
      const newStatus = destination.droppableId;
      const sourceStatusTasks = getTasksForStatus(source.droppableId);
      const sourceId = sourceStatusTasks[source.index].id;
      const destinationStatusTasks = getTasksForStatus(destination.droppableId);

      // If the destination drop status doesn't have any cards, then we only want
      // to update the task status
      const isDestinationEmpty = destinationStatusTasks.length === 0;

      if (isDestinationEmpty) {
        return dispatch(
          updateTask({
            taskId: sourceId,
            updateMap: { status: destination.droppableId },
          })
        );
      }

      // We have to take special consideration when dragging onto the end of the list
      // Is this drag/drop happening on the end of a list with at least 1 card?
      const isDestinationEndOfList =
        destinationStatusTasks.length === destination.index;

      // If its a drop at the end of a list, use the index of the last item in the list
      // so we can find the overall rank/index of that item in the sprint
      const destinationIndex = isDestinationEndOfList
        ? destination.index - 1
        : destination.index;

      // Use that index to find the related task in that status column
      // If dropped at the end, this will be the item before it
      // If dropped above a task, this will be the item below it
      const destinationId = destinationStatusTasks[destinationIndex].id;

      // Cut the dropped task out of the overall sprintTaskId list so we don't
      // have duplicates
      const filteredSprintTaskIds = sprintTaskIds.filter(
        (id) => id !== sourceId
      );

      // Get the index of the item that we want to place this task ahead of
      // Again, this is tricky...
      // If this was dropped onto the end of status list, then we need it to be the index
      // after the one above where we dropped this card
      // If this was dropped above a card, then we can proceed normally
      const filteredDestinationIndex = isDestinationEndOfList
        ? filteredSprintTaskIds.indexOf(destinationId) + 1
        : filteredSprintTaskIds.indexOf(destinationId);

      // Reorder the taskIds for the entire sprint by
      // placing all the tasks (not the one we drag/dropped) up to the location we want to drop
      // placing the one we dropped back in
      // finally, adding the rest of the items below the item we updated
      // This slicing method gives us the full array back, but with our removed item place back
      // in where we want
      const reorderedTaskIds = [
        ...filteredSprintTaskIds.slice(0, filteredDestinationIndex),
        sourceId,
        ...filteredSprintTaskIds.slice(filteredDestinationIndex),
      ];

      dispatch(
        updateSprintTaskRankAndStatus({
          sprintTaskIds: reorderedTaskIds,
          taskId: sourceId,
          status: newStatus,
        })
      );
    }
  };

  const defaultProjectId = useSelector((state) => state.user.defaultProjectId);
  const activeProjectId = useSelector((state) => state.app.activeProjectId);
  const isProjectLoaded = useSelector((state) => state.app.activeProject.id);
  useEffect(() => {
    if (activeProjectId && !isProjectLoaded) {
      dispatch(getProject({ projectId: activeProjectId }));
    }

    if (defaultProjectId && !activeProjectId && !isProjectLoaded) {
      dispatch(getProject({ projectId: defaultProjectId }));
    }
  }, [defaultProjectId, activeProjectId, isProjectLoaded, dispatch]);

  return (
    <ProtectedPage isLoading={isLoading}>
      <LayoutWithSidebar
        dynamicPageName={
          activeSprintId && sprint.status !== "Created" ? sprint.title : null
        }
        PageActionComponent={SprintPageActionComponent}
      >
        {!activeSprintId || (activeSprintId && sprint.status === "Created") ? (
          <NoDataPaperCard message="There is currently no active sprint." />
        ) : (
          <Fragment>
            <Board handleDrop={handleDrop}>
              <StatusColumn
                statusText="To Do"
                statusColor="#00AEEE"
                tasks={toDoTasks}
                handleCardClick={handleCardClick}
              />
              <StatusColumn
                statusText="Blocked"
                statusColor="#E95049"
                tasks={blockedTasks}
                handleCardClick={handleCardClick}
              />
              <StatusColumn
                statusText="In Progress"
                statusColor="#F4C95B"
                tasks={inProgressTasks}
                handleCardClick={handleCardClick}
              />
              <StatusColumn
                statusText="Done"
                statusColor="#40BB97"
                tasks={doneTasks}
                handleCardClick={handleCardClick}
              />
            </Board>
            <TaskModal
              isOpen={isTaskModalOpen}
              task={currentTask}
              handleClose={closeTaskModal}
            />
          </Fragment>
        )}
        <SprintCompleteConfirmationModal
          isOpen={isSprintConfirmationModalOpen}
          handleClose={closeSprintConfirmationModal}
        />
      </LayoutWithSidebar>
    </ProtectedPage>
  );
};

export default Home;
