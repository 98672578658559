import { configureStore, combineReducers } from "@reduxjs/toolkit";
import appReducer from "./app/reducer";
import userReducer from "./user/reducer";

const rootReducer = (state, action) => {
  return combineReducers({
    app: appReducer,
    user: userReducer,
  })(state, action);
};

const devTools =
  process.env.NODE_ENV === "development" ||
  process.env.REACT_APP_SHOW_REDUX_DEVTOOLS === "true";

const store = configureStore({
  reducer: rootReducer,
  devTools,
});

export default store;
