import { createAction } from "@reduxjs/toolkit";
import FirebaseAPI from "services/firebase";
import { user } from "store/actionTypes";
import { auth, createApiUrl } from "utilities/firebase";
import axios from "axios";
import { findApiError } from "utilities";
import { updateProfile } from "firebase/auth";
import { collection, getDocs } from "firebase/firestore";
import moment from "moment";

export const userNotLoggedIn = createAction(user.userNotLoggedIn);

const signInUserWithEmailAndPasswordPending = createAction(
  user.signInUserWithEmailAndPassword.pending
);
const signInUserWithEmailAndPasswordFulfilled = createAction(
  user.signInUserWithEmailAndPassword.fulfilled
);
const signInUserWithEmailAndPasswordRejected = createAction(
  user.signInUserWithEmailAndPassword.rejected
);

export const signInUserWithEmailAndPassword = ({ email, password }) => {
  return async (dispatch, getState) => {
    dispatch(signInUserWithEmailAndPasswordPending());

    try {
      // Sign user in with email and password
      await FirebaseAPI.signInWithEmailAndPassword({ email, password });

      // Return payload for reducer
      dispatch(signInUserWithEmailAndPasswordFulfilled({ email }));
    } catch (error) {
      dispatch(
        signInUserWithEmailAndPasswordRejected({ error: findApiError(error) })
      );
    }
  };
};

const createUserWithEmailAndPasswordPending = createAction(
  user.createUserWithEmailAndPassword.pending
);
const createUserWithEmailAndPasswordFulfilled = createAction(
  user.createUserWithEmailAndPassword.fulfilled
);
const createUserWithEmailAndPasswordRejected = createAction(
  user.createUserWithEmailAndPassword.rejected
);

export const createUserWithEmailAndPassword = ({
  displayName,
  email,
  password,
  mandatory,
  promotionCode = null,
}) => {
  return async (dispatch, getState) => {
    dispatch(createUserWithEmailAndPasswordPending());

    try {
      const mandatoryRefValue = document.querySelector("#mandatory").value;
      if (mandatory || mandatoryRefValue) {
        throw Error("A user with this email address already exists.");
      }

      // Create user account with email and password in auth and firestore
      await FirebaseAPI.createUserWithEmailAndPassword({
        email,
        password,
      });
      await updateProfile(auth.currentUser, { displayName });

      dispatch(
        createUserWithEmailAndPasswordFulfilled({
          displayName,
          email,
          promotionCode,
        })
      );
    } catch (error) {
      dispatch(
        createUserWithEmailAndPasswordRejected({ error: findApiError(error) })
      );
    }
  };
};

const userIsLoggedInPending = createAction(user.userIsLoggedIn.pending);
const userIsLoggedInFulfilled = createAction(user.userIsLoggedIn.fulfilled);
const userIsLoggedInRejected = createAction(user.userIsLoggedIn.rejected);

const getCustomClaimRole = async () => {
  await auth.currentUser.getIdToken(true);
  const decodedToken = await auth.currentUser.getIdTokenResult();
  return decodedToken.claims.stripeRole;
};

const getActiveProjectCountAllowed = (type) => {
  switch (type) {
    case "pro":
      return 25;

    case "plus":
      return 5;

    case "basic":
    default:
      return 1;
  }
};

export const userIsLoggedIn = ({ user }) => {
  return async (dispatch, getState) => {
    dispatch(userIsLoggedInPending());

    try {
      // Fetch user from firestore user auth uid
      const displayName = user.displayName;
      const { userRef, user: firestoreUser } =
        await FirebaseAPI.getFirestoreUser(user.uid);
      const subscriptionType = await getCustomClaimRole();
      const hasSubscription = !!subscriptionType;

      let subscriptionStatus = null;
      let subscriptionPeriodEnd = null;
      let subscriptionTrialPeriodEnd = null;
      let subscriptionCancelAtPeriodEnd = null;
      let activeProjectCount = 0;
      let activeProjectCountAllowed = 1;

      if (hasSubscription) {
        // Get subscription data
        const userSubscriptionsSnapshot = await getDocs(
          collection(userRef, "subscriptions")
        );

        if (userSubscriptionsSnapshot.docs.length) {
          const subDoc = userSubscriptionsSnapshot.docs[0];
          const sub = { id: subDoc.id, ...subDoc.data() };
          // console.log({ sub });

          subscriptionStatus = sub.status;
          subscriptionPeriodEnd = moment
            .unix(sub.current_period_end.seconds)
            .format("MM/DD/YYYY");
          subscriptionTrialPeriodEnd =
            subscriptionStatus === "trialing"
              ? moment.unix(sub.trial_end.seconds).format("MM/DD/YYYY")
              : null;
          subscriptionCancelAtPeriodEnd = sub.cancel_at_period_end;
        }

        activeProjectCount = firestoreUser.activeProjectCount || 0;
        activeProjectCountAllowed =
          getActiveProjectCountAllowed(subscriptionType);
      }

      // Dispatch success action for userIsLoggedIn
      dispatch(
        userIsLoggedInFulfilled({
          user: {
            ...firestoreUser,
            displayName,
            hasSubscription,
            subscriptionType,
            subscriptionStatus,
            subscriptionPeriodEnd,
            subscriptionTrialPeriodEnd,
            subscriptionCancelAtPeriodEnd,
            activeProjectCount,
            activeProjectCountAllowed,
          },
        })
      );
    } catch (error) {
      dispatch(userIsLoggedInRejected({ error: findApiError(error) }));
    }
  };
};

const signUserOutPending = createAction(user.signUserOut.pending);
const signUserOutFulfilled = createAction(user.signUserOut.fulfilled);
const signUserOutRejected = createAction(user.signUserOut.rejected);

export const signUserOut = () => {
  return async (dispatch, getState) => {
    dispatch(signUserOutPending());

    try {
      await FirebaseAPI.signUserOut();

      dispatch(signUserOutFulfilled());
    } catch (error) {
      dispatch(signUserOutRejected({ error: findApiError(error) }));
    }
  };
};

const changeEmailPending = createAction(user.changeEmail.pending);
const changeEmailFulfilled = createAction(user.changeEmail.fulfilled);
const changeEmailRejected = createAction(user.changeEmail.rejected);

export const changeEmail = ({ newEmail, currentPassword }) => {
  return async (dispatch, getState) => {
    dispatch(changeEmailPending());

    try {
      await FirebaseAPI.changeEmail({ newEmail, currentPassword });

      dispatch(changeEmailFulfilled({ email: newEmail }));
    } catch (error) {
      dispatch(changeEmailRejected({ error: findApiError(error) }));
    }
  };
};

const changePasswordPending = createAction(user.changePassword.pending);
const changePasswordFulfilled = createAction(user.changePassword.fulfilled);
const changePasswordRejected = createAction(user.changePassword.rejected);

export const changePassword = ({ newPassword, currentPassword }) => {
  return async (dispatch, getState) => {
    dispatch(changePasswordPending());

    try {
      await FirebaseAPI.changePassword({ newPassword, currentPassword });

      dispatch(changePasswordFulfilled());
    } catch (error) {
      dispatch(changePasswordRejected({ error: findApiError(error) }));
    }
  };
};

const getTodoistTokenPending = createAction(user.getTodoistToken.pending);
const getTodoistTokenFulfilled = createAction(user.getTodoistToken.fulfilled);
const getTodoistTokenRejected = createAction(user.getTodoistToken.rejected);

export const getTodoistToken = ({ state, code }) => {
  return async (dispatch, getState) => {
    dispatch(getTodoistTokenPending());

    try {
      const idToken = await auth.currentUser.getIdToken();
      const url = createApiUrl(`/todoist/link`);
      const body = { state, code };
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      const result = await axios.post(url, body, config);
      const { todoistAccessToken, todoistUser } = result.data;

      dispatch(getTodoistTokenFulfilled({ todoistAccessToken, todoistUser }));
    } catch (error) {
      dispatch(getTodoistTokenRejected({ error: findApiError(error) }));
    }
  };
};

const unlinkTodoistPending = createAction(user.unlinkTodoist.pending);
const unlinkTodoistFulfilled = createAction(user.unlinkTodoist.fulfilled);
const unlinkTodoistRejected = createAction(user.unlinkTodoist.rejected);

export const unlinkTodoist = ({ closeModal }) => {
  return async (dispatch, getState) => {
    dispatch(unlinkTodoistPending());

    const accessToken = getState().user.todoistAccessToken;

    try {
      const idToken = await auth.currentUser.getIdToken();
      const url = createApiUrl(`/todoist/unlink`);
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      const body = { accessToken };
      await axios.post(url, body, config);

      dispatch(unlinkTodoistFulfilled());
      closeModal && closeModal();
    } catch (error) {
      dispatch(unlinkTodoistRejected({ error: findApiError(error) }));
    }
  };
};
