import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Button,
  Typography,
  SvgIcon,
  Link as MaterialLink,
  TextField,
  Box,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { signInUserWithEmailAndPassword } from "store/user/actions";
import { useAuthStateChanged } from "hooks";
import { Layout } from "layouts";
import { ReactComponent as MainLogo } from "assets/scrumist-logo-full.svg";
import { ReactComponent as LogoMark } from "assets/scrumist-logo-mark.svg";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "450px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  paper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "80px",
    alignItems: "center",
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.background,
    padding: "6px 12px",
    borderRadius: "5px",
  },
  noBorder: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
}));

const Login = () => {
  const { isCheckingAuth } = useAuthStateChanged();

  const classes = useStyles();

  const dispatch = useDispatch();
  const error = useSelector((state) => state.user.formError);
  const isFormLoading = useSelector((state) => state.user.isFormLoading);

  const [formState, setFormState] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();

    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFormSubmit = async () => {
    const { email, password } = formState;
    dispatch(signInUserWithEmailAndPassword({ email, password }));
  };

  if (isCheckingAuth) {
    return (
      <Layout pageTitle="Scrumist" showNav={false}>
        <Paper elevation={0} classes={{ root: classes.paper }}>
          <Container className={classes.root}>
            <SvgIcon
              viewBox="0 0 50 76"
              style={{
                display: "block",
                marginTop: "0px",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "33px",
                width: "75px",
                height: "75px",
                position: "absolute",
                left: "calc(50% - 37.5px)",
                top: "calc(45% - 37.5px)",
              }}
            >
              <LogoMark />
            </SvgIcon>
            <CircularProgress
              style={{
                position: "absolute",
                left: "calc(50% - 75px)",
                top: "calc(45% - 75px)",
              }}
              size={150}
              color="primary"
            />
          </Container>
        </Paper>
      </Layout>
    );
  }

  return (
    <Layout pageTitle={"Login"} showNav={false}>
      <Paper elevation={0} classes={{ root: classes.paper }}>
        <Container className={classes.root}>
          <SvgIcon
            viewBox="0 0 455 82"
            style={{
              display: "block",
              width: "400px",
              height: "175px",
              margin: "0 auto",
            }}
          >
            <MainLogo />
          </SvgIcon>

          <Typography variant="h1" align="center" gutterBottom>
            Login to Scrumist
          </Typography>

          <Typography variant="h5" align="center" gutterBottom>
            Don't have an account?{" "}
            <MaterialLink component={Link} to="/signup">
              Sign up now!
            </MaterialLink>
          </Typography>

          {error && (
            <Typography
              variant="subtitle2"
              align="center"
              classes={{ root: classes.error }}
              gutterBottom
            >
              {error}
            </Typography>
          )}

          <fieldset
            style={{
              opacity: isFormLoading ? ".3" : 1,
              border: "none",
            }}
          >
            <TextField
              id="email"
              name="email"
              variant="outlined"
              label="Email Address"
              type="email"
              fullWidth
              placeholder="john.doe@scrumist.io"
              onChange={handleInputChange}
              value={formState.email}
              required
              margin="normal"
              InputProps={{
                classes: {
                  root: classes.noBorder,
                },
              }}
            />
            <TextField
              id="password"
              variant="outlined"
              label="Password"
              name="password"
              type="password"
              value={formState.password}
              onChange={handleInputChange}
              required
              margin="normal"
              fullWidth
              InputProps={{
                classes: {
                  root: classes.noBorder,
                },
              }}
            />
          </fieldset>

          <Typography variant="body2" align="left" gutterBottom>
            <MaterialLink component={Link} to="/forgot-password">
              Forgot password?
            </MaterialLink>
          </Typography>

          <Box width="100%" mt={2} boxShadow={0}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleFormSubmit}
              style={{ display: "block", width: "100%" }}
              disableRipple
              disabled={isFormLoading}
            >
              Login
            </Button>
          </Box>
        </Container>
      </Paper>
    </Layout>
  );
};

export default Login;
