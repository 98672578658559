import { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";
import SortIcon from "@material-ui/icons/Sort";
import {
  updateBacklogRanks,
  updateSprintRanks,
  taskPlanning,
  clearError,
  getProject,
} from "store/app/actions";
import { ProtectedPage, LayoutWithSidebar } from "layouts";
import {
  TaskModal,
  TaskLimitModal,
  SprintModal,
  SprintStartConfirmationModal,
} from "modals";
import DroppableTaskList from "components/DroppableTaskList";
import NoDataPaperCard from "components/NoDataPaperCard";
import { reorder } from "utilities";
import { useCheckOnboardingState } from "hooks";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: "20px",
    padding: "7px 23px 7px 23px",
    lineHeight: "25px",
    textTransform: "none",
  },
  icon: {
    width: "25px",
    height: "25px",
  },
}));

const AddTaskButton = ({ onClick }) => {
  const classes = useStyles();

  const activeTaskCount =
    useSelector((state) => state.app.activeProject.activeTaskCount) || 0;
  const activeTaskLimit = 300;

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      mt="-35px"
    >
      <Button
        variant="outlined"
        color="primary"
        size="small"
        disableRipple
        onClick={onClick}
        classes={{ root: classes.button }}
        startIcon={<AddIcon className={classes.icon} />}
      >
        Create Task
      </Button>
      <Typography variant="body2">
        {activeTaskCount} / {activeTaskLimit} Active Tasks
      </Typography>
    </Box>
  );
};

const BacklogPage = () => {
  useCheckOnboardingState();

  const dispatch = useDispatch();
  const backlogTaskIds = useSelector(
    (state) => state.app.activeProject.backlogTaskIds
  );
  const sprintTaskIds = useSelector(
    (state) => state.app.activeProject.sprintTaskIds
  );
  const activeSprintId = useSelector(
    (state) => state.app.activeProject.activeSprintId
  );
  const sprint = useSelector((state) => state.app.sprint);
  const tasks = useSelector((state) => state.app.activeProject.tasks);
  const activeTaskCount =
    useSelector((state) => state.app.activeProject.activeTaskCount) || 0;
  const activeTaskLimit = 300;
  const isLoading = useSelector((state) => state.app.isFetchingProject);
  const [isTaskModalOpen, setTaskModalOpen] = useState(false);
  const [isTaskLimitModalOpen, setTaskLimitModalOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);

  const openTaskModal = () => {
    // Check active task count before allowing it to be open
    if (activeTaskCount === activeTaskLimit) {
      setTaskLimitModalOpen(true);
    } else {
      setTaskModalOpen(true);
    }
  };

  const [isSprintModalOpen, setSprintModalOpen] = useState(false);
  const openSprintModal = () => setSprintModalOpen(true);
  const closeSprintModal = () => {
    setSprintModalOpen(false);
    dispatch(clearError());
  };

  const [isSprintConfirmationModalOpen, setSprintConfirmationModalOpen] =
    useState(false);
  const openSprintConfirmationModal = () =>
    setSprintConfirmationModalOpen(true);
  const closeSprintConfirmationModal = () =>
    setSprintConfirmationModalOpen(false);

  const handleTaskSelect = (taskId) => {
    const task = tasks.find((t) => t.id === taskId);
    if (task) {
      setCurrentTask(task);
      openTaskModal();
    }
  };
  const clearTask = () => setCurrentTask(null);
  const closeTaskModal = () => {
    setTaskModalOpen(false);
    dispatch(clearError());

    setTimeout(() => clearTask(), 500);
  };
  const closeTaskLimitModal = () => {
    setTaskLimitModalOpen(false);
  };

  const handleUpdateBacklogRanking = (updatedBacklogTaskIds) => {
    dispatch(updateBacklogRanks({ backlogTaskIds: updatedBacklogTaskIds }));
  };

  const handleUpdateSprintRanking = (updatedSprintTaskIds) => {
    dispatch(updateSprintRanks({ sprintTaskIds: updatedSprintTaskIds }));
  };

  const handleTaskPlanning = (
    updatedSprintTaskIds,
    updatedBacklogTaskIds,
    changedTaskId
  ) => {
    dispatch(
      taskPlanning({
        sprintTaskIds: updatedSprintTaskIds,
        backlogTaskIds: updatedBacklogTaskIds,
        changedTaskId,
      })
    );
  };

  const getListSettings = (listId) => {
    switch (listId) {
      case "sprint":
        return {
          taskIds: sprintTaskIds,
          updateHandler: handleUpdateSprintRanking,
        };

      case "backlog":
        return {
          taskIds: backlogTaskIds,
          updateHandler: handleUpdateBacklogRanking,
        };

      default:
        return null;
    }
  };

  const handleDrop = (props) => {
    const { destination, source } = props;

    // If not dropped into a droppable container, don't make any updates
    if (!destination) return;

    // If we are updating the current ranking within this list
    if (destination.droppableId === source.droppableId) {
      const settings = getListSettings(source.droppableId);
      const reorderedTaskIds = reorder(
        settings.taskIds,
        source.index,
        destination.index
      );
      settings.updateHandler(reorderedTaskIds);
    } else {
      // Determine which list need an item to be removed
      const sourceListSettings = getListSettings(source.droppableId);
      const removedTaskId = sourceListSettings.taskIds[source.index];
      const updatedSourceTaskIds = sourceListSettings.taskIds.filter(
        (taskId) => taskId !== removedTaskId
      );

      // Determine which list an item needs adding
      const destinationListSettings = getListSettings(destination.droppableId);
      const addedTaskId = removedTaskId;
      const updatedDestinationTaskIds = [
        ...destinationListSettings.taskIds.slice(0, destination.index),
        addedTaskId,
        ...destinationListSettings.taskIds.slice(destination.index),
      ];

      const updatedSprintTaskIds =
        source.droppableId === "backlog"
          ? updatedDestinationTaskIds
          : updatedSourceTaskIds;

      const updatedBacklogTaskIds =
        source.droppableId === "backlog"
          ? updatedSourceTaskIds
          : updatedDestinationTaskIds;

      handleTaskPlanning(
        updatedSprintTaskIds,
        updatedBacklogTaskIds,
        addedTaskId
      );
    }
  };

  const customPageLayoutStyles = {
    filter: isTaskModalOpen ? "blur(3px)" : "",
  };

  const handleBacklogSort = () => {
    const backlogTasks = tasks.filter((t) => backlogTaskIds.includes(t.id));
    const sortedBacklogTaskIds = backlogTasks
      .sort((taskA, taskB) => taskB.priority - taskA.priority)
      .map((t) => t.id);

    const areArraysEqual = (first, second) => {
      // Are they both Arrays
      if (!Array.isArray(first) || !Array.isArray(second)) return false;

      // Do they have the same length
      if (first.length !== second.length) return false;

      // Do they have the same values
      // NOTE: ONLY SUPPORTING PRIMITIVES
      const isEqual = first.reduce((areAllValuesEqual, currentValue, index) => {
        // Once a single value has found to be false, we are done
        if (!areAllValuesEqual) return false;

        // Compare values
        return currentValue === second[index];
      }, true);

      return isEqual;
    };

    // If arrays are not equal then we should call the update action
    const shouldBacklogUpdate = !areArraysEqual(
      backlogTaskIds,
      sortedBacklogTaskIds
    );

    // End this silently
    if (shouldBacklogUpdate) handleUpdateBacklogRanking(sortedBacklogTaskIds);

    // TODO: Be nice to have this in a flag in redux called isBacklogDirty so we can disable button
  };

  const SprintListActionBar = () => {
    const isStartSprintDisabled = sprintTaskIds.length === 0;
    const noSprint = !activeSprintId;
    const sprintNotStarted = activeSprintId && sprint.status === "Created";
    const sprintStarted = activeSprintId && sprint.status === "Started";

    if (noSprint) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="medium"
          disableRipple
          onClick={openSprintModal}
          style={{ textTransform: "none" }}
        >
          Create Sprint
        </Button>
      );
    }

    if (sprintNotStarted) {
      return (
        <Fragment>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            disableRipple
            style={{ marginRight: "10px", textTransform: "none" }}
            onClick={openSprintModal}
          >
            Edit Sprint
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            disableRipple
            style={{ textTransform: "none" }}
            disabled={isStartSprintDisabled}
            onClick={openSprintConfirmationModal}
          >
            Start Sprint
          </Button>
        </Fragment>
      );
    }

    if (sprintStarted) {
      return (
        <Fragment>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            disableRipple
            style={{ textTransform: "none" }}
            onClick={openSprintModal}
          >
            Edit Sprint
          </Button>
        </Fragment>
      );
    }

    return null;
  };

  const BacklogListActionBar = () => {
    return (
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        disableRipple
        endIcon={<SortIcon />}
        style={{ textTransform: "none" }}
        onClick={handleBacklogSort}
      >
        Sort By Priority
      </Button>
    );
  };

  const SprintListNoData = (props) => {
    const noDataMessage = activeSprintId
      ? "There are currently no tasks planned for this sprint."
      : "There is currently no sprint in progress.";

    return (
      <NoDataPaperCard
        highlight={props.isDraggingOver}
        message={noDataMessage}
      />
    );
  };

  const BacklogListNoData = (props) => {
    const noDataMessage = "There are currently no tasks in the backlog.";
    return (
      <NoDataPaperCard
        highlight={props.isDraggingOver}
        message={noDataMessage}
      />
    );
  };

  const defaultProjectId = useSelector((state) => state.user.defaultProjectId);
  const activeProjectId = useSelector((state) => state.app.activeProjectId);
  const isProjectLoaded = useSelector((state) => state.app.activeProject.id);
  useEffect(() => {
    if (activeProjectId && !isProjectLoaded) {
      dispatch(getProject({ projectId: activeProjectId }));
    }

    if (defaultProjectId && !activeProjectId && !isProjectLoaded) {
      dispatch(getProject({ projectId: defaultProjectId }));
    }
  }, [defaultProjectId, activeProjectId, isProjectLoaded, dispatch]);

  return (
    <ProtectedPage>
      <LayoutWithSidebar
        pageStyles={customPageLayoutStyles}
        PageActionComponent={() => {
          return <div />;
        }}
      >
        <DragDropContext onDragEnd={handleDrop}>
          <DroppableTaskList
            listId="sprint"
            title={activeSprintId ? sprint.title : "Current Sprint"}
            tasks={tasks}
            taskIds={sprintTaskIds}
            isLoading={isLoading}
            selectTask={handleTaskSelect}
            ActionBarComponent={SprintListActionBar}
            NoDataComponent={SprintListNoData}
          />
          <DroppableTaskList
            listId="backlog"
            title="Backlog"
            tasks={tasks}
            taskIds={backlogTaskIds}
            isLoading={isLoading}
            selectTask={handleTaskSelect}
            ActionBarComponent={BacklogListActionBar}
            NoDataComponent={BacklogListNoData}
            containerStyles={{ marginTop: "25px" }}
          />
        </DragDropContext>
        <AddTaskButton onClick={openTaskModal} />
        <TaskModal
          isOpen={isTaskModalOpen}
          task={currentTask}
          handleClose={closeTaskModal}
        />
        <TaskLimitModal
          isOpen={isTaskLimitModalOpen}
          handleClose={closeTaskLimitModal}
        />
        <SprintModal
          isOpen={isSprintModalOpen}
          activeSprintId={activeSprintId}
          sprint={sprint}
          handleClose={closeSprintModal}
        />
        <SprintStartConfirmationModal
          isOpen={isSprintConfirmationModalOpen}
          sprintTitle={sprint.title}
          handleClose={closeSprintConfirmationModal}
        />
      </LayoutWithSidebar>
    </ProtectedPage>
  );
};
export default BacklogPage;
