import { createReducer } from "@reduxjs/toolkit";
import { user, app } from "store/actionTypes";

const defaultActiveProject = {
  id: null,
  name: null,
  activeSprintId: null,
  sprintIds: [],
  tasks: [],
  taskIds: [],
  sprintTaskIds: [],
  backlogTaskIds: [],
  activeTaskCount: null,
  todoistId: null,
  userId: null,
};

const defaultSprint = {
  id: null,
  title: "",
  startDate: "",
  endDate: "",
  description: "",
  status: null,
  taskIds: [],
  projectId: null,
  userId: null,
};

const defaultTask = {
  id: null,
  title: null,
  description: null,
  dueDate: null,
  priority: null,
  projectId: null,
  sprintId: null,
  status: null,
  storyPoints: null,
  todoistId: null,
  userId: null,
};

const initialState = {
  isInitialized: false,
  isLoading: true,

  // List of todoist projects fetched for connect project
  todoistProjects: [],

  // List of Scrumist projects fetched for projects page
  projects: [],

  // Active project on active sprint and backlog pages
  activeProjectId: null,
  activeProject: defaultActiveProject,

  // Active sprint in current active project
  sprint: defaultSprint,

  // UI State Helpers
  isFetchingProject: true,
  isConnectingProject: false,
  isCrupdateSprintPending: false,
  formSuccess: false,
  isFormLoading: false,
  error: null,
};

const appReducer = createReducer(initialState, {
  /**
   * HANDLE USER ACTIONS
   */
  [user.userNotLoggedIn]: (state, action) => {
    return { ...state, isInitialized: true, isLoading: false };
  },
  [user.userIsLoggedIn.pending]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [user.userIsLoggedIn.fulfilled]: (state, action) => {
    return { ...state, isInitialized: true, isLoading: false };
  },
  [user.unlinkTodoist.fulfilled]: (state, action) => {
    return {
      ...state,
      projects: initialState.projects,
      activeProject: initialState.activeProject,
      sprint: initialState.sprint,
    };
  },
  [user.signUserOut.fulfilled]: (state, action) => {
    return initialState;
  },

  /**
   * HANDLE APP ACTIONS
   */
  [app.clearError]: (state, action) => {
    return { ...state, error: null };
  },
  [app.setActiveProject]: (state, action) => {
    const { projectId } = action.payload;
    return {
      ...state,
      activeProjectId: projectId,
      projects: initialState.projects,
    };
  },
  [app.getTodoistProjects.pending]: (state, action) => {
    return { ...state, isFormLoading: true, error: null };
  },
  [app.getTodoistProjects.fulfilled]: (state, action) => {
    const { todoistProjects } = action.payload;
    return { ...state, todoistProjects, isFormLoading: false };
  },
  [app.getTodoistProjects.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, error, isFormLoading: false };
  },
  [app.listProjects.pending]: (state, action) => {
    return {
      ...state,
      activeProject: initialState.activeProject,
      isFormLoading: true,
      error: null,
    };
  },
  [app.listProjects.fulfilled]: (state, action) => {
    const { projects } = action.payload;
    return { ...state, projects, isFormLoading: false };
  },
  [app.listProjects.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, error, isFormLoading: false };
  },
  [app.setDefaultProject.pending]: (state, action) => {
    return {
      ...state,
      isFormLoading: true,
      error: null,
    };
  },
  [app.setDefaultProject.fulfilled]: (state, action) => {
    const { projectId } = action.payload;
    return {
      ...state,
      projects: state.projects.map((project) => {
        return {
          ...project,
          isDefault: project.id === projectId,
        };
      }),
      isFormLoading: false,
    };
  },
  [app.setDefaultProject.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, error, isFormLoading: false };
  },
  [app.disconnectProject.pending]: (state, action) => {
    return {
      ...state,
      isFormLoading: true,
      error: null,
    };
  },
  [app.disconnectProject.fulfilled]: (state, action) => {
    const { projectId } = action.payload;
    return {
      ...state,
      projects: state.projects.filter((project) => project.id !== projectId),
      isFormLoading: false,
    };
  },
  [app.disconnectProject.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, error, isFormLoading: false };
  },
  [app.connectProject.pending]: (state, action) => {
    return { ...state, isConnectingProject: true, error: "" };
  },
  [app.connectProject.fulfilled]: (state, action) => {
    const { projectId } = action.payload;

    return {
      ...state,
      activeProjectId: projectId,
      projects: initialState.projects,
      todoistProjects: initialState.todoistProjects,
      isConnectingProject: false,
      isFetchingProject: false,
    };
  },
  [app.connectProject.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, error, isConnectingProject: false };
  },
  [app.getProject.pending]: (state, action) => {
    return { ...state, isFetchingProject: true, error: "" };
  },
  [app.getProject.fulfilled]: (state, action) => {
    const { project, sprint, tasks, backlogTaskIds, sprintTaskIds } =
      action.payload;

    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        ...project,
        tasks,
        backlogTaskIds,
        sprintTaskIds,
      },
      sprint: sprint
        ? {
            ...state.sprint,
            ...sprint,
          }
        : state.sprint,
      isFetchingProject: false,
    };
  },
  [app.getProject.rejected]: (state, action) => {
    const { projectId, error } = action.payload;
    return { ...state, error, activeProjectId: null, isFetchingProject: false };
  },
  [app.createTask.pending]: (state, action) => {
    return { ...state, isFormLoading: true, error: "" };
  },
  [app.createTask.fulfilled]: (state, action) => {
    const { task } = action.payload;

    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        taskIds: state.activeProject.taskIds.concat(task.id),
        activeTaskCount: state.activeProject.activeTaskCount + 1,
        tasks: state.activeProject.tasks.concat(task),
        backlogTaskIds: state.activeProject.backlogTaskIds.concat(task.id),
      },
      error: "",
      isFormLoading: false,
    };
  },
  [app.createTask.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, isFormLoading: false, error };
  },
  [app.updateTask.pending]: (state, action) => {
    const { taskId, updateMap } = action.payload;
    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        tasks: state.activeProject.tasks.map((t) => {
          if (t.id === taskId) {
            return {
              ...t,
              ...updateMap,
            };
          }
          return t;
        }),
      },
      error: "",
      isFormLoading: true,
    };
  },
  [app.updateTask.fulfilled]: (state, action) => {
    return { ...state, isFormLoading: false, error: "" };
  },
  [app.updateTask.rejected]: (state, action) => {
    const { error, taskId, oldTask } = action.payload;

    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        tasks: state.activeProject.tasks.map((t) => {
          if (t.id === taskId) {
            return oldTask;
          }
          return t;
        }),
      },
      error,
      isFormLoading: false,
    };
  },
  [app.getSprint.pending]: (state, action) => {
    return { ...state, isFetchingSprint: true, error: false };
  },
  [app.getSprint.fulfilled]: (state, action) => {
    const { title, startDate, endDate, description, status, taskIds, tasks } =
      action.payload;

    const combinedTasks = state.activeProject.tasks
      .concat(tasks)
      .reduce((tasks, currentTask) => {
        const taskAlreadyExists = tasks.find(
          (task) => task.id === currentTask.id
        );

        if (taskAlreadyExists) {
          return tasks;
        }

        return tasks.concat(currentTask);
      }, []);

    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        sprintTaskIds: taskIds,
        tasks: combinedTasks,
      },
      sprint: {
        ...state.sprint,
        title,
        startDate,
        endDate,
        description,
        status,
      },
      isFetchingSprint: false,
    };
  },
  [app.getSprint.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, isFetchingSprint: false, error };
  },
  [app.createSprint.pending]: (state, action) => {
    return { ...state, isFormLoading: true, error: false };
  },
  [app.createSprint.fulfilled]: (state, action) => {
    const { sprint } = action.payload;

    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        activeSprintId: sprint.id,
      },
      sprint,
      isFormLoading: false,
      formSuccess: true,
    };
  },
  [app.createSprint.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, error, isFormLoading: false };
  },
  [app.updateSprint.pending]: (state, action) => {
    return { ...state, isFormLoading: true };
  },
  [app.updateSprint.fulfilled]: (state, action) => {
    const { title, startDate, endDate, description } = action.payload;

    return {
      ...state,
      isFormLoading: false,
      sprint: {
        ...state.sprint,
        title,
        startDate,
        endDate,
        description,
      },
    };
  },
  [app.updateSprint.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, isFormLoading: false, error };
  },
  [app.startSprint.pending]: (state, action) => {
    return { ...state };
  },
  [app.startSprint.fulfilled]: (state, action) => {
    const { status } = action.payload;
    return {
      ...state,
      sprint: {
        ...state.sprint,
        status,
      },
    };
  },
  [app.startSprint.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, error };
  },
  [app.completeSprint.pending]: (state, action) => {
    return { ...state, activeSprintId: null };
  },
  [app.completeSprint.fulfilled]: (state, action) => {
    const { rolloverSprint, activeTaskCountChange } = action.payload;

    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        activeTaskCount:
          state.activeProject.activeTaskCount + activeTaskCountChange,
        sprintIds: state.activeProject.sprintIds.concat(rolloverSprint.id),
        activeSprintId: rolloverSprint.id,
        sprintTaskIds: rolloverSprint.taskIds,
        tasks: state.activeProject.tasks
          .filter((t) => t.status !== "Done")
          .map((t) => {
            return {
              ...t,
              sprintId: rolloverSprint.id,
            };
          }),
      },
      sprint: rolloverSprint,
    };
  },
  [app.completeSprint.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, error };
  },
  [app.updateBacklogRanks.pending]: (state, action) => {
    const { backlogTaskIds } = action.payload;
    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        backlogTaskIds,
      },
    };
  },
  [app.updateBacklogRanks.fulfilled]: (state, action) => {
    return state;
  },
  [app.updateBacklogRanks.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, error };
  },
  [app.updateSprintRanks.pending]: (state, action) => {
    const { sprintTaskIds } = action.payload;
    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        sprintTaskIds,
      },
    };
  },
  [app.updateSprintRanks.fulfilled]: (state, action) => {
    return state;
  },
  [app.updateSprintRanks.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, error };
  },
  [app.updateSprintTaskRankAndStatus.pending]: (state, action) => {
    const { sprintTaskIds, taskId, status } = action.payload;

    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        sprintTaskIds,
        tasks: state.activeProject.tasks.map((t) => {
          if (t.id === taskId) {
            return { ...t, status };
          }
          return t;
        }),
      },
    };
  },
  [app.updateSprintTaskRankAndStatus.fulfilled]: (state, action) => {
    return state;
  },
  [app.updateSprintTaskRankAndStatus.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, error };
  },
  [app.taskPlanning.pending]: (state, action) => {
    const { sprintTaskIds, backlogTaskIds } = action.payload;
    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        sprintTaskIds,
        backlogTaskIds,
      },
    };
  },
  [app.taskPlanning.fulfilled]: (state, action) => {
    return state;
  },
  [app.taskPlanning.rejected]: (state, action) => {
    const { error, sprintTaskIds, backlogTaskIds } = action.payload;
    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        sprintTaskIds,
        backlogTaskIds,
      },
      error,
    };
  },
});

export default appReducer;
