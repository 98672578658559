import { getFirestore, doc, getDoc } from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import moment from "moment";
import { app, auth } from "utilities/firebase";

const db = getFirestore(app);

const SPRINT_STATUS = {
  CREATED: "Created",
  STARTED: "Started",
  COMPLETED: "Completed",
};

// "2014-09-08T08:02:17-05:00" (ISO 8601)
const getNow = () => moment().format();

const FirebaseAPI = {
  createUserWithEmailAndPassword: async ({ email, password }) =>
    createUserWithEmailAndPassword(auth, email, password),

  signInWithEmailAndPassword: ({ email, password }) =>
    signInWithEmailAndPassword(auth, email, password),

  signUserOut: () => signOut(auth),

  changeEmail: async ({ newEmail, currentPassword }) => {
    const user = auth.currentUser;
    const currentEmail = user.email;

    await signInWithEmailAndPassword(auth, currentEmail, currentPassword);
    return user.updateEmail(newEmail);
  },

  changePassword: async ({ newPassword, currentPassword }) => {
    const user = auth.currentUser;
    const currentEmail = user.email;

    await signInWithEmailAndPassword(auth, currentEmail, currentPassword);
    return user.updatePassword(newPassword);
  },

  getFirestoreUser: async (userId) => {
    const userDoc = await getDoc(doc(db, "users", userId));

    if (!userDoc.exists) throw Error("User doesn't exist in database.");

    return { userRef: userDoc.ref, user: userDoc.data() };
  },
};

export default FirebaseAPI;
