import { createAction } from "@reduxjs/toolkit";
import { app } from "store/actionTypes";
import { auth, createApiUrl } from "utilities/firebase";
import axios from "axios";
import { findApiError } from "utilities";

export const clearError = createAction(app.clearError);

const getTodoistProjectsPending = createAction(app.getTodoistProjects.pending);
const getTodoistProjectsFulfilled = createAction(
  app.getTodoistProjects.fulfilled
);
const getTodoistProjectsRejected = createAction(
  app.getTodoistProjects.rejected
);

export const getTodoistProjects = ({ onSuccess, onError }) => {
  return async (dispatch, getState) => {
    dispatch(getTodoistProjectsPending());

    try {
      // Get user Todoist access token from redux state
      const accessToken = getState().user.todoistAccessToken;

      // Get all Todoist projects for user
      const idToken = await auth.currentUser.getIdToken();
      const url = createApiUrl(`/todoist/projects?accessToken=${accessToken}`);
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      const result = await axios.get(url, config);
      const projects = result.data.projects;

      // Return payload for reducer
      dispatch(getTodoistProjectsFulfilled({ todoistProjects: projects }));

      if (projects.length) {
        onSuccess && onSuccess();
      } else {
        onError &&
          onError(
            "You have no projects in Todoist available to connect. Please create a new project in Todoist first."
          );
      }
    } catch (error) {
      dispatch(getTodoistProjectsRejected({ error: findApiError(error) }));
    }
  };
};

const listProjectsPending = createAction(app.listProjects.pending);
const listProjectsFulfilled = createAction(app.listProjects.fulfilled);
const listProjectsRejected = createAction(app.listProjects.rejected);

export const listProjects = () => {
  return async (dispatch, getState) => {
    dispatch(listProjectsPending());

    try {
      // Get all projects for user
      const idToken = await auth.currentUser.getIdToken();
      const url = createApiUrl(`/projects`);
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      const result = await axios.get(url, config);

      const defaultProjectId = getState().user.defaultProjectId;
      const projects = result.data.projects.map((p) => {
        return {
          ...p,
          isDefault: p.id === defaultProjectId,
        };
      });

      // Return payload for reducer
      dispatch(listProjectsFulfilled({ projects }));
    } catch (error) {
      dispatch(listProjectsRejected({ error: findApiError(error) }));
    }
  };
};

const setActiveProjectById = createAction(app.setActiveProject);
export const setActiveProject = ({ projectId }) => {
  return async (dispatch, getState) => {
    dispatch(setActiveProjectById({ projectId }));
  };
};

const connectProjectPending = createAction(app.connectProject.pending);
const connectProjectFulfilled = createAction(app.connectProject.fulfilled);
const connectProjectRejected = createAction(app.connectProject.rejected);

export const connectProject = ({
  projectId: todoistProjectId,
  projectName: todoistProjectName,
  onSuccess,
  onError,
}) => {
  return async (dispatch, getState) => {
    dispatch(connectProjectPending());

    try {
      // Get user Todoist access token from redux state
      const accessToken = getState().user.todoistAccessToken;

      // Get all Todoist projects for user
      const idToken = await auth.currentUser.getIdToken();
      const url = createApiUrl(`/todoist/connectProject`);
      const body = {
        accessToken,
        projectId: todoistProjectId,
        projectName: todoistProjectName,
      };
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      const result = await axios.post(url, body, config);
      const { projectId, projectName, tasks } = result.data;

      dispatch(
        connectProjectFulfilled({
          projectId,
          projectName,
          tasks,
          todoistProjectId,
        })
      );

      onSuccess && onSuccess();
    } catch (error) {
      onError && onError(findApiError(error));
      dispatch(connectProjectRejected({ error: findApiError(error) }));
    }
  };
};

const setDefaultProjectPending = createAction(app.setDefaultProject.pending);
const setDefaultProjectFulfilled = createAction(
  app.setDefaultProject.fulfilled
);
const setDefaultProjectRejected = createAction(app.setDefaultProject.rejected);

export const setDefaultProject = ({ projectId, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    dispatch(setDefaultProjectPending());

    try {
      if (projectId) {
        const idToken = await auth.currentUser.getIdToken();
        const url = createApiUrl(`/project/${projectId}/default`);
        const config = { headers: { Authorization: `Bearer ${idToken}` } };
        await axios.put(url, {}, config);
      }

      dispatch(setDefaultProjectFulfilled({ projectId }));
      onSuccess && onSuccess();
    } catch (error) {
      onError && onError(findApiError(error));
      dispatch(setDefaultProjectRejected({ error: findApiError(error) }));
    }
  };
};

const disconnectProjectPending = createAction(app.disconnectProject.pending);
const disconnectProjectFulfilled = createAction(
  app.disconnectProject.fulfilled
);
const disconnectProjectRejected = createAction(app.disconnectProject.rejected);

export const disconnectProject = ({ projectId, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    dispatch(disconnectProjectPending());

    try {
      const idToken = await auth.currentUser.getIdToken();
      const url = createApiUrl(`/todoist/project/${projectId}/disconnect`);
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      await axios.delete(url, config);

      const newDefaultProjectId =
        getState().app.projects.filter((p) => p.id !== projectId)[0]?.id ||
        null;
      dispatch(disconnectProjectFulfilled({ projectId }));

      dispatch(setDefaultProject({ projectId: newDefaultProjectId }));

      onSuccess && onSuccess();
    } catch (error) {
      onError && onError(findApiError(error));
      dispatch(disconnectProjectRejected({ error: findApiError(error) }));
    }
  };
};

/**
 * Get, Create, and Update Task Action Creators
 */
const getProjectPending = createAction(app.getProject.pending);
const getProjectFulfilled = createAction(app.getProject.fulfilled);
const getProjectRejected = createAction(app.getProject.rejected);

export const getProject = ({ projectId }) => {
  return async (dispatch, getState) => {
    dispatch(getProjectPending());

    try {
      const idToken = await auth.currentUser.getIdToken();
      const url = createApiUrl(`/project/${projectId}`);
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      const result = await axios.get(url, config);
      const { project, sprint, tasks } = result.data;
      const backlogTaskIds = tasks.filter((t) => !t.sprintId).map((t) => t.id);
      const sprintTaskIds = tasks.filter((t) => t.sprintId).map((t) => t.id);

      // Return payload for reducer (just passing along to save it locally in this case)
      dispatch(
        getProjectFulfilled({
          project,
          sprint,
          tasks,
          backlogTaskIds,
          sprintTaskIds,
        })
      );
    } catch (error) {
      dispatch(getProjectRejected({ projectId, error: findApiError(error) }));
    }
  };
};

const createTaskPending = createAction(app.createTask.pending);
const createTaskFulfilled = createAction(app.createTask.fulfilled);
const createTaskRejected = createAction(app.createTask.rejected);

export const createTask = ({ newTask, closeModal }) => {
  return async (dispatch, getState) => {
    dispatch(createTaskPending());

    try {
      const projectId = getState().app.activeProject.id;
      const todoistProjectId = getState().app.activeProject.todoistId;
      const todoistAccessToken = getState().user.todoistAccessToken;

      const idToken = await auth.currentUser.getIdToken();
      const url = createApiUrl(`/task`);
      const body = {
        projectId,
        todoistProjectId,
        todoistAccessToken,
        ...newTask,
      };
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      const result = await axios.post(url, body, config);
      const { task } = result.data;

      dispatch(createTaskFulfilled({ task }));
      closeModal && closeModal();
    } catch (error) {
      dispatch(createTaskRejected({ error: findApiError(error) }));
    }
  };
};

const updateTaskPending = createAction(app.updateTask.pending);
const updateTaskFulfilled = createAction(app.updateTask.fulfilled);
const updateTaskRejected = createAction(app.updateTask.rejected);

export const updateTask = ({ taskId, updateMap: taskUpdates, closeModal }) => {
  return async (dispatch, getState) => {
    const oldTask = getState().app.activeProject.tasks.find(
      (t) => t.id === taskId
    );

    dispatch(updateTaskPending({ taskId, updateMap: taskUpdates }));

    try {
      const todoistAccessToken = getState().user.todoistAccessToken;

      const idToken = await auth.currentUser.getIdToken();
      const url = createApiUrl(`/task/${taskId}`);
      const body = {
        todoistAccessToken,
        taskUpdates,
        oldTask,
      };
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      await axios.put(url, body, config);

      dispatch(updateTaskFulfilled());
      closeModal && closeModal();
    } catch (error) {
      dispatch(
        updateTaskRejected({ taskId, oldTask, error: findApiError(error) })
      );
    }
  };
};

/**
 * Get, Create, Update, and Start Sprint Action Creators
 */
const createSprintPending = createAction(app.createSprint.pending);
const createSprintFulfilled = createAction(app.createSprint.fulfilled);
const createSprintRejected = createAction(app.createSprint.rejected);

export const createSprint = ({
  title,
  startDate,
  endDate,
  description,
  closeModal,
  // taskIds = [], // Not used yet
}) => {
  return async (dispatch, getState) => {
    dispatch(createSprintPending());

    try {
      const projectId = getState().app.activeProject.id;
      const idToken = await auth.currentUser.getIdToken();
      const url = createApiUrl(`/sprint`);
      const body = {
        projectId,
        title,
        startDate,
        endDate,
        description,
      };
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      const result = await axios.post(url, body, config);
      const { sprint } = result.data;

      dispatch(createSprintFulfilled({ sprint }));
      closeModal && closeModal();
    } catch (error) {
      dispatch(createSprintRejected({ error: findApiError(error) }));
    }
  };
};

const updateSprintPending = createAction(app.updateSprint.pending);
const updateSprintFulfilled = createAction(app.updateSprint.fulfilled);
const updateSprintRejected = createAction(app.updateSprint.rejected);

export const updateSprint = ({
  title,
  startDate,
  endDate,
  description,
  closeModal,
}) => {
  return async (dispatch, getState) => {
    dispatch(updateSprintPending());

    try {
      const activeSprintId = getState().app.activeProject.activeSprintId;
      const idToken = await auth.currentUser.getIdToken();
      const url = createApiUrl(`/sprint/${activeSprintId}`);
      const body = {
        title,
        startDate,
        endDate,
        description,
      };
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      await axios.put(url, body, config);

      dispatch(
        updateSprintFulfilled({
          title,
          startDate,
          endDate,
          description,
        })
      );
      closeModal && closeModal();
    } catch (error) {
      dispatch(updateSprintRejected({ error: findApiError(error) }));
    }
  };
};

const startSprintPending = createAction(app.startSprint.pending);
const startSprintFulfilled = createAction(app.startSprint.fulfilled);
const startSprintRejected = createAction(app.startSprint.rejected);

export const startSprint = () => {
  return async (dispatch, getState) => {
    dispatch(startSprintPending());

    try {
      const activeSprintId = getState().app.activeProject.activeSprintId;
      const idToken = await auth.currentUser.getIdToken();
      const url = createApiUrl(`/sprint/${activeSprintId}/start`);
      const body = {};
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      const result = await axios.post(url, body, config);
      const { status } = result.data;

      dispatch(startSprintFulfilled({ status }));
    } catch (error) {
      dispatch(startSprintRejected({ error: findApiError(error) }));
    }
  };
};

const completeSprintPending = createAction(app.completeSprint.pending);
const completeSprintFulfilled = createAction(app.completeSprint.fulfilled);
const completeSprintRejected = createAction(app.completeSprint.rejected);

export const completeSprint = () => {
  return async (dispatch, getState) => {
    const activeSprintId = getState().app.activeProject.activeSprintId;

    // Important: Sets activeSprintId to null
    dispatch(completeSprintPending());

    try {
      const projectId = getState().app.activeProject.id;
      const todoistAccessToken = getState().user.todoistAccessToken;
      const sprintTaskIds = getState().app.activeProject.sprintTaskIds;
      const sprintTasks = getState().app.activeProject.tasks.filter((t) =>
        sprintTaskIds.includes(t.id)
      );
      const doneTasks = sprintTasks.filter((t) => t.status === "Done");

      const completedTaskIds = doneTasks.map((t) => t.id);
      const completedTodoistTaskIds = doneTasks.map((t) => t.todoistId);

      const rolloverTaskIds = sprintTasks
        .filter((t) => t.status !== "Done")
        .map((t) => t.id);

      const idToken = await auth.currentUser.getIdToken();
      const url = createApiUrl(`/sprint/${activeSprintId}/complete`);
      const body = {
        projectId,
        todoistAccessToken,
        completedTaskIds,
        completedTodoistTaskIds,
        rolloverTaskIds,
      };
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      const result = await axios.post(url, body, config);
      const { rolloverSprint, activeTaskCountChange } = result.data;

      dispatch(
        completeSprintFulfilled({ rolloverSprint, activeTaskCountChange })
      );
    } catch (error) {
      dispatch(completeSprintRejected({ error: findApiError(error) }));
    }
  };
};

const updateBacklogRanksPending = createAction(app.updateBacklogRanks.pending);
const updateBacklogRanksFulfilled = createAction(
  app.updateBacklogRanks.fulfilled
);
const updateBacklogRanksRejected = createAction(
  app.updateBacklogRanks.rejected
);

export const updateBacklogRanks = ({ backlogTaskIds }) => {
  return async (dispatch, getState) => {
    // Update redux state in pending as an optimistic update of the new rank order
    dispatch(updateBacklogRanksPending({ backlogTaskIds }));

    try {
      const projectId = getState().app.activeProject.id;
      const sprintTaskIds = getState().app.activeProject.sprintTaskIds;
      const idToken = await auth.currentUser.getIdToken();
      const url = createApiUrl(`/tasks/backlog/rank`);
      const body = {
        projectId,
        sprintTaskIds,
        backlogTaskIds,
      };
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      await axios.put(url, body, config);

      dispatch(updateBacklogRanksFulfilled());
    } catch (error) {
      dispatch(updateBacklogRanksRejected({ error: findApiError(error) }));
    }
  };
};

const updateSprintRanksPending = createAction(app.updateSprintRanks.pending);
const updateSprintRanksFulfilled = createAction(
  app.updateSprintRanks.fulfilled
);
const updateSprintRanksRejected = createAction(app.updateSprintRanks.rejected);

export const updateSprintRanks = ({ sprintTaskIds }) => {
  return async (dispatch, getState) => {
    // Update redux state in pending as an optimistic update of the new rank order
    dispatch(updateSprintRanksPending({ sprintTaskIds }));

    try {
      const sprintId = getState().app.activeProject.activeSprintId;
      const projectId = getState().app.activeProject.id;
      const backlogTaskIds = getState().app.activeProject.backlogTaskIds;
      const idToken = await auth.currentUser.getIdToken();
      const url = createApiUrl(`/tasks/backlog/rank`);
      const body = {
        projectId,
        sprintTaskIds,
        backlogTaskIds,
        sprintId,
      };
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      await axios.put(url, body, config);

      dispatch(updateSprintRanksFulfilled());
    } catch (error) {
      dispatch(updateSprintRanksRejected({ error: findApiError(error) }));
    }
  };
};

const updateSprintTaskRankAndStatusPending = createAction(
  app.updateSprintTaskRankAndStatus.pending
);
const updateSprintTaskRankAndStatusFulfilled = createAction(
  app.updateSprintTaskRankAndStatus.fulfilled
);
const updateSprintTaskRankAndStatusRejected = createAction(
  app.updateSprintTaskRankAndStatus.rejected
);

export const updateSprintTaskRankAndStatus = ({
  sprintTaskIds,
  taskId,
  status,
}) => {
  return async (dispatch, getState) => {
    // Update redux state in pending as an optimistic update of the new rank order
    dispatch(
      updateSprintTaskRankAndStatusPending({ sprintTaskIds, taskId, status })
    );

    try {
      const sprintId = getState().app.activeProject.activeSprintId;
      const projectId = getState().app.activeProject.id;
      const backlogTaskIds = getState().app.activeProject.backlogTaskIds;
      const idToken = await auth.currentUser.getIdToken();
      const url = createApiUrl(`/tasks/sprint/task/${taskId}`);
      const body = {
        projectId,
        sprintTaskIds,
        backlogTaskIds,
        sprintId,
        status,
      };
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      await axios.put(url, body, config);

      dispatch(updateSprintTaskRankAndStatusFulfilled());
    } catch (error) {
      dispatch(
        updateSprintTaskRankAndStatusRejected({ error: findApiError(error) })
      );
    }
  };
};

const taskPlanningPending = createAction(app.taskPlanning.pending);
const taskPlanningFulfilled = createAction(app.taskPlanning.fulfilled);
const taskPlanningRejected = createAction(app.taskPlanning.rejected);

export const taskPlanning = ({
  changedTaskId,
  backlogTaskIds,
  sprintTaskIds,
}) => {
  return async (dispatch, getState) => {
    // Save old state for rewinding on error
    const previousBacklogTaskIds = getState().app.activeProject.backlogTaskIds;
    const previousSprintTaskIds = getState().app.activeProject.sprintTaskIds;

    // Update redux state in pending as an optimistic update of the new rank order
    dispatch(taskPlanningPending({ backlogTaskIds, sprintTaskIds }));

    const projectId = getState().app.activeProject.id;
    const activeSprintId = getState().app.activeProject.activeSprintId;
    const taskSprintId = sprintTaskIds.includes(changedTaskId)
      ? activeSprintId
      : null;

    if (activeSprintId) {
      try {
        const idToken = await auth.currentUser.getIdToken();
        const url = createApiUrl(`/task/${changedTaskId}/plan`);
        const body = {
          projectId,
          backlogTaskIds,
          sprintTaskIds,
          taskSprintId,
          sprintId: activeSprintId,
        };
        const config = { headers: { Authorization: `Bearer ${idToken}` } };
        await axios.put(url, body, config);

        dispatch(taskPlanningFulfilled());
      } catch (error) {
        dispatch(
          taskPlanningRejected({
            error: findApiError(error),
            backlogTaskIds: previousBacklogTaskIds,
            sprintTaskIds: previousSprintTaskIds,
          })
        );
      }
    } else {
      dispatch(
        taskPlanningRejected({
          error: "Create a sprint before adding tasks to it.",
          backlogTaskIds: previousBacklogTaskIds,
          sprintTaskIds: previousSprintTaskIds,
        })
      );
    }
  };
};
