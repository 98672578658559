import { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import { changeEmail } from "store/user/actions";

const useModalStyles = makeStyles((theme) => ({
  paper: {},
  paperWidthMd: {
    maxWidth: "800px",
    margin: "0 auto",
  },
  title: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "60px",
    paddingRight: "60px",
    paddingTop: "50px",
  },
  titleIcon: {
    color: " #00C99A",
    height: "100%",
    marginRight: "6px",
  },
  titleText: {
    color: "#3F3D56",
    fontSize: "26px",
    letterSpacing: "0.64px",
    lineHeight: "36px",
    fontWeight: "500",
  },
  actions: {
    paddingRight: "38px",
    paddingBottom: "42px",
  },
  button: {
    margin: theme.spacing(1),
    textTransform: "capitalize",
  },
  outlinedButton: {
    color: "#00C99A",
    border: "2px solid #00C99A",
  },
  containedButton: {
    backgroundColor: "#00C99A",
    color: "#fff",
  },
  noBorder: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
}));

const useDialogContentStyles = makeStyles((theme) => ({
  root: {
    overflowY: "initial",
    paddingLeft: "60px",
    paddingRight: "60px",
    marginBottom: "25px",
  },
}));

const ChangeEmailForm = ({
  newEmail,
  currentPassword,
  handleTextFieldChange,
}) => {
  const classes = useModalStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TextField
          type="password"
          id="currentPassword"
          name="currentPassword"
          autoComplete="new-password" // trick chrome into not autofilling entire form
          margin="normal"
          variant="filled"
          label="Current Password"
          fullWidth
          value={currentPassword}
          onChange={handleTextFieldChange}
          InputProps={{
            classes: {
              root: classes.noBorder,
            },
          }}
        />
        <TextField
          type="email"
          id="newEmail"
          name="newEmail"
          autoComplete="new-email"
          margin="normal"
          variant="filled"
          label="New Email Address"
          fullWidth
          value={newEmail}
          onChange={handleTextFieldChange}
          InputProps={{
            classes: {
              root: classes.noBorder,
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

const ChangeEmailModal = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const classes = useModalStyles();
  const dialogContentClasses = useDialogContentStyles();
  const modalTitle = "Change Email Address";
  const modalActionButtonText = "Update";

  const [formState, updateFormState] = useState({
    newEmail: "", // disabled autofill for "newEmail"
    currentPassword: "",
  });

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;

    updateFormState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeEmail = () => {
    dispatch(
      changeEmail({
        newEmail: formState.newEmail,
        currentPassword: formState.currentPassword,
      })
    );
    updateFormState({
      newEmail: "",
      currentPassword: "",
    });
    handleClose();
  };

  const handleSubmitAction = handleChangeEmail;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
      className={`${classes.paperWidthMd}`}
      aria-labelledby="Change-Email-Modal"
    >
      <DialogTitle id={modalTitle} className={classes.title} disableTypography>
        <EmailIcon className={classes.titleIcon} />
        <Typography variant="h3" className={classes.titleText}>
          {modalTitle}
        </Typography>
      </DialogTitle>
      <DialogContent className={dialogContentClasses.root}>
        <ChangeEmailForm
          newEmail={formState.newEmail}
          currentPassword={formState.currentPassword}
          handleTextFieldChange={handleTextFieldChange}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="outlined"
          size="large"
          className={`${classes.button} ${classes.outlinedButton}`}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disableElevation
          size="large"
          className={`${classes.button} ${classes.containedButton}`}
          onClick={handleSubmitAction}
        >
          {modalActionButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeEmailModal;
