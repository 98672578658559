import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography,
  Box,
} from "@material-ui/core";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: "700px",
    margin: "0 auto",
    padding: "25px",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "0px",
    marginBottom: "15px",
  },
  titleIcon: {
    color: " #00C99A",
    height: "26px",
    width: "26px",
    marginRight: "8px",
  },
  closeIcon: {
    height: "28px",
    width: "28px",
    cursor: "pointer",

    "&:hover": {
      color: "#616362",
    },
  },
  titleText: {},
  actions: {
    padding: "0px",
  },
  noBorder: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
  dialog: {
    overflowY: "initial",
    padding: "0px",
    paddingLeft: "10px",
  },
}));

const TaskLimitModal = ({ isOpen, handleClose }) => {
  const classes = useStyles();
  const modalTitle = "Task Limit";

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
      classes={{ paper: classes.paper }}
      aria-labelledby="Task-Limit-Modal"
    >
      <DialogTitle id={modalTitle} className={classes.title} disableTypography>
        <Box display="flex" alignItems="center" width="100%">
          <PriorityHighIcon className={classes.titleIcon} />
          <Typography variant="h4" className={classes.titleText}>
            {modalTitle}
          </Typography>
        </Box>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </DialogTitle>
      <DialogContent className={classes.root}>
        <DialogContentText variant="body1" style={{ fontSize: "1.1rem" }}>
          You currently have the maximum amount of active tasks.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          disableRipple
          style={{
            boxSizing: "border-box",
            width: "95px",
          }}
          onClick={handleClose}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaskLimitModal;
