import { Draggable } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Box } from "@material-ui/core";
import FlagIcon from "@material-ui/icons/Flag";
import styled from "styled-components";
import { displayDate } from "utilities";

const PointsPill = ({ points }) => {
  return (
    <Typography
      variant="body2"
      style={{
        height: "22px",
        width: "20px",
        padding: "4px 6px",
        borderRadius: "5px",
        color: "#40BB97",
        backgroundColor: "#ececec",
        boxSizing: "border-box",
        lineHeight: "15px",
      }}
    >
      {points ? points : "-"}
    </Typography>
  );
};

const getFlagStyles = (priority) => {
  const defaultStyles = { height: "24px", width: "24px", marginRight: "3px" };
  switch (priority) {
    case 1:
      return { ...defaultStyles, color: "rgba(63,61,86,0.3)" };

    case 2:
      return { ...defaultStyles, color: "#15B2E6" };

    case 3:
      return { ...defaultStyles, color: "#FCC840" };

    case 4:
      return { ...defaultStyles, color: "#FC4040" };

    default:
      return null;
  }
};

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
    width: "100%",
    height: "165px",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    border: "1px solid #f7f7f7",
    boxShadow: "1px 1px 4px #e5e9e6",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "11px",
    paddingTop: "12px",
    marginBottom: "15px",

    "&:last-child": {
      marginBottom: "0px",
    },

    "&:hover": {
      cursor: "pointer",
      boxShadow: "1px 1px 4px 3px #e5e9e6",
    },

    "&:active": {
      cursor: "grab",
    },
  },
}));

const Card = ({ task, index, onCardClick }) => {
  const classes = useStyles();

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided, { isDragging }) => {
        return (
          <Paper
            elevation={0}
            onClick={() => onCardClick(task.id)}
            ref={provided.innerRef}
            classes={{ root: classes.card }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Typography
              variant="subtitle2"
              style={{
                maxHeight: "calc(100% - 36px)",
                overflow: "hidden",
              }}
            >
              {task.title}
            </Typography>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              height="36px"
              paddingTop="5px"
            >
              <Box display="flex" height="100%" alignItems="center">
                <FlagIcon style={getFlagStyles(task.priority)} />
                <Typography variant="caption" style={{ color: "#959595" }}>
                  {displayDate(task.dueDate)}
                </Typography>
              </Box>
              <PointsPill points={task.storyPoints} />
            </Box>
          </Paper>
        );
      }}
    </Draggable>
  );
};

export default Card;
