import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LayersIcon from "@material-ui/icons/Layers";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as MainLogo } from "assets/scrumist-logo-full.svg";
import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
    padding: "32px",
    zIndex: 1,
    borderRight: "1px solid #eaeaea",
  },
  listItem: {
    width: "100%",
    padding: "12px 25px 11px 12px",
    color: "#7E7E7E",
    borderRadius: "7px",
    marginBottom: "7px",

    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#d9f3fc",
    },

    "&:hover": {
      backgroundColor: "#eaeaea",
      borderRadius: "7px",
    },
  },
  listIcon: {
    color: "#7E7E7E",
    minWidth: 0,
    marginRight: "17px",
    "& > svg": {
      width: "30px",
      height: "30px",
    },
  },
  listItemSelected: {
    color: "#00AEEE",
    backgroundColor: "#d9f3fc",
    "& > .MuiListItemIcon-root": {
      color: "#00AEEE",
    },
  },
}));

const Sidebar = ({ routes }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogoClick = () => {
    navigate("/app");
  };

  const onRouteClick = (pathname) => {
    navigate(pathname);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <SvgIcon
        viewBox="0 0 455 82"
        style={{
          display: "block",
          width: "100%",
          height: "35px",
          cursor: "pointer",
          marginBottom: "57px",
        }}
        onClick={handleLogoClick}
      >
        <MainLogo />
      </SvgIcon>
      <List>
        {routes.map(({ pathname, name, Icon }, i) => {
          const isActive = pathname === location.pathname;

          return (
            <ListItem
              button={Boolean(pathname)}
              key={i}
              classes={{
                root: classes.listItem,
                selected: classes.listItemSelected,
              }}
              onClick={() => pathname && onRouteClick(pathname)}
              disableRipple
              selected={isActive}
            >
              <ListItemIcon
                classes={{
                  root: classes.listIcon,
                }}
              >
                <Icon />
              </ListItemIcon>
              <ListItemText
                primary={name}
                primaryTypographyProps={{ variant: "subtitle2" }}
              />
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
};

export default Sidebar;
