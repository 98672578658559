import styled from "styled-components";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FlagIcon from "@material-ui/icons/Flag";
import { displayDate } from "utilities";
import { Box, Typography } from "@material-ui/core";

const PointsPill = ({ points }) => {
  return (
    <Typography
      variant="body1"
      style={{
        height: "100%",
        padding: "6px",
        borderRadius: "5px",
        color: "#7E7E7E",
        backgroundColor: "#ececec",
        boxSizing: "border-box",
        lineHeight: "15px",
      }}
    >
      {points ? points : "-"}
    </Typography>
  );
};

const TaskStatusBadge = ({ status }) => {
  let color,
    backgroundColor = "";
  const defaultStyles = {
    height: "100%",
    fontWeight: "bold",
    padding: "2px 12px",
    borderRadius: "5px",
    margin: "0px 20px",
  };
  const formattedStatus = status.toUpperCase();

  switch (status) {
    case "To Do":
      color = "#00AEEE";
      backgroundColor = "#d4f1f9";

      return (
        <Typography
          variant="subtitle1"
          style={{
            ...defaultStyles,
            color,
            backgroundColor,
          }}
        >
          {formattedStatus}
        </Typography>
      );

    case "Blocked":
      color = "#E95049";
      backgroundColor = "#f7e3e1";

      return (
        <Typography
          variant="subtitle1"
          style={{
            ...defaultStyles,
            color,
            backgroundColor,
          }}
        >
          {formattedStatus}
        </Typography>
      );

    case "In Progress":
      color = "#F2DA24";
      backgroundColor = "#f8f7db";

      return (
        <Typography
          variant="subtitle1"
          style={{
            ...defaultStyles,
            color,
            backgroundColor,
          }}
        >
          {formattedStatus}
        </Typography>
      );

    case "Done":
      color = "#40BB97";
      backgroundColor = "#def3ed";

      return (
        <Typography
          variant="subtitle1"
          style={{
            ...defaultStyles,
            color,
            backgroundColor,
          }}
        >
          {formattedStatus}
        </Typography>
      );

    default:
      return null;
  }
};

const getFlagStyles = (priority) => {
  const defaultStyles = { height: "26px" };
  switch (priority) {
    case 1:
      return { ...defaultStyles, color: "rgba(63,61,86,0.3)" };

    case 2:
      return { ...defaultStyles, color: "#15B2E6" };

    case 3:
      return { ...defaultStyles, color: "#FCC840" };

    case 4:
      return { ...defaultStyles, color: "#FC4040" };

    default:
      return null;
  }
};

const BacklogTaskCard = ({ task }) => {
  return (
    <Box width="100%" height="100%" display="flex">
      <Box width="calc(100% - 240px)" height="100%" alignSelf="flex-start">
        <Typography
          variant="h6"
          style={{
            maxWidth: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {task.title}
        </Typography>
        <Typography
          variant="caption"
          component="div"
          style={{ color: "#959595" }}
        >
          {displayDate(task.dueDate)}
        </Typography>
      </Box>
      <Box
        width="240px"
        height="100%"
        paddingTop="10px"
        paddingBottom="10px"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <FlagIcon style={getFlagStyles(task.priority)} />
        <TaskStatusBadge status={task.status} />
        <PointsPill points={task.storyPoints} />
      </Box>
    </Box>
  );
};

export default BacklogTaskCard;
