import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import { useNavigate } from "react-router-dom";

// This is where we would setup our styles
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

const LinkTodoistModal = ({ open, onClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleLinkClick = () => {
    // Won't redirect to Todoist unless you serve with Firebase (npm run serve)
    navigate("/auth/todoist");
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Welcome to Scrumist!"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Let's Link Your Scrumist Account with Todoist
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button onClick={handleLinkClick} color="primary" autoFocus>
          Link Account
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LinkTodoistModal;
