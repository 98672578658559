import { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography,
  TextField,
  Box,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import CloseIcon from "@material-ui/icons/Close";
import { unlinkTodoist } from "store/user/actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: "700px",
    margin: "0 auto",
    padding: "25px",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "0px",
    marginBottom: "15px",
  },
  titleIcon: {
    color: " #00C99A",
    height: "26px",
    width: "26px",
    marginRight: "8px",
  },
  closeIcon: {
    height: "28px",
    width: "28px",
    cursor: "pointer",

    "&:hover": {
      color: "#616362",
    },
  },
  titleText: {},
  actions: {
    padding: "0px",
  },
  noBorder: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
  dialog: {
    overflowY: "initial",
    padding: "0px",
    paddingLeft: "10px",
  },
}));

const UnlinkTodoistConfirmationModal = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const modalTitle = "Unlink Todoist";

  const [isLoading, setIsLoading] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const isButtonDisabled =
    confirmText.trim().toLowerCase() !== "reset account" || isLoading;

  const handleConfirmTextChange = (e) => {
    setConfirmText(e.target.value);
  };

  const handleUnlinkTodoist = () => {
    setIsLoading(true);
    dispatch(unlinkTodoist({ closeModal: handleClose }));
  };

  const handleSubmitAction = handleUnlinkTodoist;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
      classes={{ paper: classes.paper }}
      aria-labelledby="Unlink-Todoist-Confirmation-Modal"
    >
      <DialogTitle id={modalTitle} className={classes.title} disableTypography>
        <Box display="flex" alignItems="center" width="100%">
          <WarningIcon className={classes.titleIcon} />
          <Typography variant="h4" className={classes.titleText}>
            {modalTitle}
          </Typography>
        </Box>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <DialogContentText
          id={`${modalTitle}-description`}
          style={{ color: "#3F3D56" }}
        >
          <Typography variant="body1" gutterBottom>
            Are you sure you want to unlink your Todoist account?
          </Typography>
          <Typography variant="body1" gutterBottom>
            This action <strong>resets your Scrumist Account</strong> and{" "}
            <strong>cannot</strong> be undone.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Please type <strong>reset account</strong> to confirm this action.
          </Typography>

          <fieldset
            style={{
              opacity: isLoading ? ".3" : 1,
              border: "none",
            }}
          >
            <TextField
              id="confirmReset"
              name="confirmReset"
              variant="outlined"
              label="Confirm Reset"
              margin="normal"
              value={confirmText}
              onChange={handleConfirmTextChange}
              InputProps={{
                classes: {
                  root: classes.noBorder,
                },
              }}
            />
          </fieldset>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disableRipple
          disabled={isButtonDisabled}
          onClick={handleSubmitAction}
        >
          I Want To Reset My Scrumist Account
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnlinkTodoistConfirmationModal;
