const createAsyncActionNames = (name) => ({
  pending: `${name}/pending`,
  fulfilled: `${name}/fulfilled`,
  rejected: `${name}/rejected`,
});

export const user = {
  userNotLoggedIn: "user/userNotLoggedIn",
  userIsLoggedIn: createAsyncActionNames("user/userIsLoggedIn"),
  signUserOut: createAsyncActionNames("user/signUserOut"),
  changeEmail: createAsyncActionNames("user/changeEmail"),
  changePassword: createAsyncActionNames("user/changePassword"),
  signInUserWithEmailAndPassword: createAsyncActionNames(
    "user/signInUserWithEmailAndPassword"
  ),
  createUserWithEmailAndPassword: createAsyncActionNames(
    "user/createUserWithEmailAndPassword"
  ),
  getTodoistToken: createAsyncActionNames("user/getTodoistToken"),
  unlinkTodoist: createAsyncActionNames("user/unlinkTodoist"),
};

export const app = {
  clearError: "app/clearError",
  setActiveProject: "app/setActiveProject",
  listProjects: createAsyncActionNames("app/listProjects"),
  setDefaultProject: createAsyncActionNames("app/setDefaultProject"),
  disconnectProject: createAsyncActionNames("app/disconnectProject"),
  getTodoistProjects: createAsyncActionNames("app/getTodoistProjects"),
  connectProject: createAsyncActionNames("app/connectProject"),
  getProject: createAsyncActionNames("app/getProject"),
  createTask: createAsyncActionNames("app/createTask"),
  updateTask: createAsyncActionNames("app/updateTask"),
  getSprint: createAsyncActionNames("app/getSprint"),
  createSprint: createAsyncActionNames("app/createSprint"),
  updateSprint: createAsyncActionNames("app/updateSprint"),
  startSprint: createAsyncActionNames("app/startSprint"),
  completeSprint: createAsyncActionNames("app/completeSprint"),
  updateBacklogRanks: createAsyncActionNames("app/updateBacklogRanks"),
  updateSprintRanks: createAsyncActionNames("app/updateSprintRanks"),
  updateSprintTaskRankAndStatus: createAsyncActionNames(
    "app/updateSprintTaskRankAndStatus"
  ),
  taskPlanning: createAsyncActionNames("app/taskPlanning"),
};
