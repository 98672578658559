import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    mode: "light",
    common: {
      black: "#212121",
      white: "#ffffff",
    },
    primary: {
      main: "#40BB97",
    },
    secondary: {
      main: "#00AEEE",
    },
    error: {
      main: "#E95049",
      background: "#f7e3e1",
    },
    warning: {
      main: "#F4C95B",
    },
    info: {
      main: "#00AEEE",
    },
    success: {
      main: "#40BB97",
    },
    text: {
      primary: "#212121",
      secondary: "#7E7E7E",
      disabled: "#959595",
    },
    divider: "#dadada",
    background: {
      default: "#ffffff",
      paper: "#f9fdfb",
    },
  },
  shape: {
    borderRadius: 7,
  },
  typography: {
    fontFamily: ["Rubik", "Roboto", "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: 20,
    htmlFontSize: 20,
    color: "#212121",
    h1: {
      fontWeight: "500",
      fontSize: "1.6rem",
    },
    h2: {
      fontWeight: "500",
      fontSize: "1.4rem",
    },
    h3: {
      fontWeight: "500",
      fontSize: "1.3rem",
    },
    h4: {
      fontWeight: "500",
      fontSize: "1.2rem",
    },
    h5: {
      fontWeight: "500",
      fontSize: "1.1rem",
    },
    h6: {
      fontSize: "1.0rem",
      fontWeight: "500",
    },
    subtitle1: {
      fontSize: ".8rem",
      fontWeight: "400",
    },
    subtitle2: {
      fontSize: ".8rem",
      fontWeight: "500",
    },
    body1: {
      fontSize: ".8rem",
      fontWeight: "400",
    },
    body2: {
      fontSize: ".7rem",
      fontWeight: "400",
    },
    button: {
      fontSize: ".7rem",
      fontWeight: "500",
    },
    caption: {
      fontSize: ".6rem",
      fontWeight: "400",
    },
    overline: {
      fontSize: ".5rem",
      fontWeight: "400",
    },
  },

  overrides: {
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: "#00AEEE",
        },
      },
      outlined: {
        boxShadow:
          "0px 2px 4px 0px rgb(64 187 151 / 20%), 0px 1px 2px 0px rgb(64 187 151 / 20%)",
        "&:hover": {
          backgroundColor: "#40BB97",
          color: "#fff",
        },
      },
      outlinedSizeSmall: {
        fontSize: ".7rem",
      },
      outlinedSizeMedium: {
        fontSize: ".8rem",
      },
      outlinedSizeLarge: {
        fontSize: ".9rem",
      },
      containedSizeSmall: {
        fontSize: ".7rem",
      },
      containedSizeMedium: {
        fontSize: ".8rem",
      },
      containedSizeLarge: {
        fontSize: ".9rem",
      },
      outlinedPrimary: {
        borderWidth: "2px",
        boxShadow:
          "0px 2px 4px 0px rgb(64 187 151 / 20%), 0px 1px 2px 0px rgb(64 187 151 / 20%)",
        "&:hover": {
          borderWidth: "2px",
          backgroundColor: "#40BB97",
          color: "#fff",
          boxShadow:
            "0px 2px 4px 0px rgb(64 187 151 / 20%), 0px 1px 2px 0px rgb(64 187 151 / 20%)",
        },
      },
      contained: {
        boxShadow:
          "0px 2px 4px 0px rgb(64 187 151 / 20%), 0px 1px 2px 0px rgb(64 187 151 / 20%)",
        "&:hover": {
          backgroundColor: "#00AEEE",
          boxShadow:
            "0px 2px 4px 0px rgb(0 174 238 / 20%), 0px 1px 2px 0px rgb(0 174 238 / 20%)",
        },
      },
      containedPrimary: {
        background: "#40BB97",
        color: "#fff",

        "&:hover": {
          background: `radial-gradient(
              circle at top left,
              #00AEEE,
              transparent 100px
            ),
            radial-gradient(
              circle at top right,
              #40BB97,
              transparent 100px
            ),
            radial-gradient(
              at bottom left,
              #F4C95B,
              transparent 400px
            ),
            radial-gradient(
              at bottom right,
              #40BB97,
              transparent 400px
            )`,
        },
      },
      containedSecondary: {
        color: "#fff",
      },
    },
    MuiPaper: {
      elevation8: {
        boxShadow:
          "0px 8px 16px 3px rgb(64 187 151 / 20%), 0px 4px 8px 2px rgb(64 187 151 / 20%), 0px 1px 2px 1px rgb(64 187 151 / 20%)",
        "&:hover": {
          boxShadow:
            "0px 8px 16px 6px rgb(64 187 151 / 20%), 0px 4px 8px 4px rgb(64 187 151 / 20%), 0px 1px 2px 2px rgb(64 187 151 / 20%)",
        },
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: "#fff",
      },
    },
  },
});

export default theme;
