import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getTodoistToken } from "store/user/actions";
import { ProtectedPage } from "layouts";

const AuthCallbackPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const accessToken = useSelector((state) => state.user.todoistAccessToken);
  const isLoggedIn = useSelector((state) => state.user.loggedIn);

  const stateParam = searchParams.get("state");
  const codeParam = searchParams.get("code");

  useEffect(() => {
    if (accessToken) navigate("/");

    if (isLoggedIn && !accessToken && !!stateParam && !!codeParam) {
      dispatch(getTodoistToken({ state: stateParam, code: codeParam }));
    }
  }, [isLoggedIn, stateParam, codeParam, accessToken, dispatch, navigate]);

  return (
    <ProtectedPage isLoading={true}>
      <div />
    </ProtectedPage>
  );
};

export default AuthCallbackPage;
