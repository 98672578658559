import moment from "moment";

export const displayDate = (date) => {
  if (!date) return "";

  const today = moment(Date.now());

  // Overdue
  if (moment(date).isBefore(today, "day")) return "Overdue";

  // Today
  if (moment(date).isSame(today, "day")) return "Today";

  // Tomorrow
  if (moment(date).isBefore(today.add(moment.duration(2, "d"))))
    return "Tomorrow";

  // Some Day in the next week
  if (moment(date).isBefore(today.add(moment.duration(4, "d"))))
    return moment(date).format("dddd");

  return moment(date).format("MMM D");
};

const getFirebaseErrorMessage = (code) => {
  switch (code) {
    case "auth/wrong-password":
    case "auth/user-not-found":
      return "Email or password is incorrect.";

    case "auth/email-already-in-use":
      return "This email is already in use.";

    case "INVALID_OOB_CODE":
    case "auth/invalid-action-code":
      return "Confirmation code has expired.";

    default:
      return "Something went wrong!";
  }
};

export const findApiError = (error) => {
  if (error.name === "FirebaseError") {
    const foundError = getFirebaseErrorMessage(error.code);
    console.log({ error, foundError });
    return foundError;
  }

  if (error.message === "INVALID_OOB_CODE") {
    const foundError = getFirebaseErrorMessage(error.message);
    console.log({ error, foundError });
    return foundError;
  }

  const foundError = error.response?.data?.error || error.message;
  console.log({ error, foundError });
  return foundError;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
