import { createReducer } from "@reduxjs/toolkit";
import { user, app } from "store/actionTypes";

const defaultTodoistUser = { full_name: "", avatar_big: "" };

const initialState = {
  loggedIn: false,

  // Sign up
  displayName: null,
  email: null,

  // Link todoist
  todoistAccessToken: null,
  todoistUser: defaultTodoistUser,

  // Connect project
  defaultProjectId: null,
  activeProjectCount: null,
  activeProjectCountAllowed: null,

  // Selecting subscription
  stripeId: null,
  stripeLink: null,
  hasSubscription: null,
  subscriptionType: null,
  subscriptionStatus: null,
  subscriptionPeriodEnd: null,
  subscriptionTrialPeriodEnd: null,
  subscriptionCancelAtPeriodEnd: null,

  // Checkout
  promotionCode: null,

  // UI
  isFormLoading: false,
  formError: "",
  getTokenError: null,
  error: null,
};

const userReducer = createReducer(initialState, {
  /**
   * HANDLE USER ACTIONS
   */
  [user.userNotLoggedIn]: (state, action) => {
    return { ...state, loggedIn: false };
  },
  [user.userIsLoggedIn.pending]: (state, action) => {
    return state;
  },
  [user.userIsLoggedIn.fulfilled]: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      ...user,
      loggedIn: true,
      isFormLoading: false,
    };
  },
  [user.userIsLoggedIn.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, error };
  },
  [user.signInUserWithEmailAndPassword.pending]: (state, action) => {
    return { ...state, isFormLoading: true, formError: "" };
  },
  [user.signInUserWithEmailAndPassword.fulfilled]: (state, action) => {
    const { displayName, email } = action.payload;
    return {
      ...state,
      displayName,
      email,
    };
  },
  [user.signInUserWithEmailAndPassword.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, formError: error, isFormLoading: false };
  },
  [user.createUserWithEmailAndPassword.pending]: (state, action) => {
    return { ...state, isFormLoading: true, formError: "" };
  },
  [user.createUserWithEmailAndPassword.fulfilled]: (state, action) => {
    const { displayName, email, promotionCode } = action.payload;
    return {
      ...state,
      displayName,
      email,
      promotionCode,
      loggedIn: true,
      isFormLoading: false,
    };
  },
  [user.createUserWithEmailAndPassword.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, formError: error, isFormLoading: false };
  },
  [user.getTodoistToken.pending]: (state, action) => {
    return state;
  },
  [user.getTodoistToken.fulfilled]: (state, action) => {
    const { todoistAccessToken, todoistUser } = action.payload;
    return { ...state, todoistAccessToken, todoistUser };
  },
  [user.getTodoistToken.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, getTokenError: error };
  },
  [user.unlinkTodoist.pending]: (state, action) => {
    return state;
  },
  [user.unlinkTodoist.fulfilled]: (state, action) => {
    return {
      ...state,
      todoistAccessToken: initialState.todoistAccessToken,
      todoistUser: initialState.todoistUser,
      defaultProjectId: initialState.defaultProjectId,
    };
  },
  [user.unlinkTodoist.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, error };
  },
  [user.signUserOut.pending]: (state, action) => {
    return state;
  },
  [user.signUserOut.fulfilled]: (state, action) => {
    return initialState;
  },
  [user.signUserOut.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, error };
  },
  [user.changeEmail.pending]: (state, action) => {
    return state;
  },
  [user.changeEmail.fulfilled]: (state, action) => {
    const { email } = action.payload;
    return { ...state, email };
  },
  [user.changeEmail.rejected]: (state, action) => {
    const { error } = action.payload;
    return { ...state, error };
  },

  /**
   * HANDLE APP ACTIONS
   */
  [app.connectProject.fulfilled]: (state, action) => {
    const { projectId } = action.payload;

    return {
      ...state,
      defaultProjectId: projectId,
      activeProjectCount: state.activeProjectCount + 1,
    };
  },
  [app.listProjects.fulfilled]: (state, action) => {
    const { projects } = action.payload;
    return {
      ...state,
      activeProjectCount: projects.length,
      isFormLoading: false,
    };
  },
  [app.setDefaultProject.fulfilled]: (state, action) => {
    const { projectId } = action.payload;

    return { ...state, defaultProjectId: projectId };
  },
  [app.disconnectProject.fulfilled]: (state, action) => {
    const { projectId } = action.payload;
    return {
      ...state,
      activeProjectCount: state.activeProjectCount - 1,
    };
  },
  [app.getProject.rejected]: (state, action) => {
    const { projectId, error } = action.payload;
    const updatedDefaultProjectId =
      projectId === state.defaultProjectId ? null : state.defaultProjectId;

    return {
      ...state,
      error,
      defaultProjectId: updatedDefaultProjectId,
      isFetchingProject: false,
    };
  },
});

export default userReducer;
